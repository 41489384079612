import _ from 'lodash';

const Audio = {
	button_click: require('./button_click.mp3'),
	card_deal: require('./deal_card.mp3'),
	flip_card: require('./flip_card.mp3'),
	turn_start: require('./turn_start.mp3'),
	bonus: require('./bonus.mp3'),
	knock: require('./knock.mp3'),
	card_drop: require('./card_drop.mp3'),
	pick_card: require('./pick_card.mp3'),
	timer_end: require('./timer_end.mp3'),
	loss: require('./loss.mp3'),
	win: require('./win.mp3'),
	card_spread: require('./card_spread.mp3'),

	// @todo draw audio
};

export type I_audio = keyof typeof Audio;

export const AUDIO_PATHS = Object.fromEntries(_.map(_.keys(Audio), (key) => [key, key])) as {
	[key in I_audio]: I_audio;
};

export default Audio;
