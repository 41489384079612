import { Box } from '@mui/material';
import React from 'react';
import _ from 'lodash';

import ProfilePic, { RING_CHILD_VALUE } from 'src/common/ProfilePic';
import CommonUserSection from 'src/common/CommonUserSection';

const styles = {
	left_button: {
		position: 'absolute',
		width: 40,
		height: 40,
		left: '22%',
	},
	right_button: {
		position: 'absolute',
		width: 40,
		height: 40,
		right: '22%',
	},
};

interface Props {
	children?: React.ReactNode;
	is_opponent?: boolean;
	profile_picture?: string;
	left_button?: React.ReactNode;
	right_button?: React.ReactNode;
	show_profile?: boolean;
}

const UserSection = ({ is_opponent, children, profile_picture, left_button, right_button, show_profile = true }: Props) => {
	return (
		<CommonUserSection is_opponent={is_opponent}>
			{show_profile && (
				<ProfilePic
					container_styles={{ top: -50, position: 'absolute', left: 0, right: 0 }}
					profile_picture={profile_picture}
					ring_child={{
						value: RING_CHILD_VALUE.PROFILE_PIC,
						onClick: _.noop,
					}}
				/>
			)}

			{left_button && <Box sx={[is_opponent ? { top: 20 } : { bottom: 20 }, styles.left_button]}>{left_button}</Box>}
			{right_button && <Box sx={[is_opponent ? { top: 20 } : { bottom: 20 }, styles.right_button]}>{right_button}</Box>}

			{children}
		</CommonUserSection>
	);
};

export default UserSection;
