import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { useShallow } from 'zustand/react/shallow';

import Animations from 'src/assets/animations/Animations';
import theme from 'src/utils/theme';
import ImageLinks from 'src/assets/images/ImageLinks';
import Text from 'src/common/@the-source/atoms/Text';
import NetworkIcon from 'src/common/NetworkIcon';
import IconButtonPrimary from 'src/common/@the-source/atoms/IconButtonPrimary';
import CONSTANTS from 'src/utils/constants';
import useApp from 'src/store/useApp';
import useGameStore from 'src/store/useGameStore';
import { GAME_CONSTANTS } from '../Game/constants';
import { RouteNames, router } from 'src/utils/navigation';
import api_requests from 'src/utils/api_requests';
import { quit_webview } from 'src/utils/helper';
import useReconnectSocketBackground from 'src/hooks/useReconnectSocketBackground';
import UserSection from './components/UserSection';

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.black,
		height: '100%',
		width: '100%',
		backgroundImage: `url(${ImageLinks.game_bg})`,
		backgrouncSize: 'cover',
	},
	opponent_section: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: GAME_CONSTANTS.USER_SECTION_HEIGHT,
	},
	matchmaking: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		height: GAME_CONSTANTS.GAME_AREA_HEIGHT,
		width: GAME_CONSTANTS.GAME_AREA_WIDTH,
	},
	centered_content: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 200,
		width: 300,
	},
	countdown_shadow: {
		position: 'absolute',
		fontSize: 140,
		textShadow: '0px 4px 0 #FFFBCB',
	},
	countdown: {
		position: 'absolute',
		background: `linear-gradient(180deg, ${theme.colors.putty} 35.62%, ${theme.colors.lemon_chiffon} 54.95%, ${theme.colors.putty} 74.68%)`,
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontSize: 140,
	},
	match_starting: {
		color: theme.colors.white,
		fontSize: 20,
		fontFamily: theme.fonts.brawler_regular,
	},
	lottie_container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	lottie_style: {
		width: '70%',
	},
};

const MatchMakingComp = () => {
	const { event_data } = useGameStore(
		useShallow((state) => ({
			event_data: state.event_data,
		})),
	);

	const { remainingTime = 0, profileInfo = {} } = event_data?.extra || {};
	const { pp } = profileInfo;

	const [timer, set_timer] = useState(remainingTime as number);

	const { show_generic_modal, show_loader, hide_loader, hide_generic_modal } = useApp(
		useShallow((state) => ({
			show_generic_modal: state.show_generic_modal,
			show_loader: state.show_loader,
			hide_loader: state.hide_loader,
			hide_generic_modal: state.hide_generic_modal,
		})),
	);

	useEffect(() => {
		const interval = setInterval(() => {
			set_timer((prev_timer) => {
				if (prev_timer === 2) {
					window.navigator?.vibrate?.(200);
				}
				if (prev_timer > 0) {
					return prev_timer - 1;
				} else {
					clearInterval(interval);
					return 0;
				}
			});
		}, 1_000);

		return () => clearInterval(interval);
	}, [timer]);

	const handle_leave_game = () => {
		show_generic_modal({
			title: 'Leave Game',
			sub_title: 'Are you sure you want to leave the game? The game will be started soon.',
			primary_btn_text: 'Leave',
			should_close_on_button_click: false,
			on_primary_press: async () => {
				try {
					show_loader();
					await api_requests.game.de_register();
					if (CONSTANTS.IS_FREEWIN()) {
						quit_webview();
					} else {
						hide_generic_modal();
						// @todo Go to registration screen
					}
				} catch (err) {
				} finally {
					hide_loader();
				}
			},
		});
	};

	useReconnectSocketBackground();

	return (
		<Box sx={styles.container}>
			<UserSection
				is_opponent={true}
				show_profile={false}
				left_button={
					<IconButtonPrimary
						onClick={handle_leave_game}
						icon={<ImageLinks.exit_matchmaking_icon width={'100%'} height={'100%'} />}
					/>
				}
			/>

			<Box sx={styles.matchmaking}>
				<Box sx={styles.centered_content}>
					<Text sx={styles.match_starting}>Match starting in</Text>
					<Box sx={styles.centered_content}>
						<Text bold sx={styles.countdown_shadow}>
							{timer}
						</Text>
						<Text bold sx={styles.countdown}>
							{timer}
						</Text>
					</Box>
				</Box>
				<Box sx={styles.lottie_container}>
					<Lottie animationData={Animations.loading_animation} loop autoplay style={styles.lottie_style} />
				</Box>
			</Box>

			<UserSection
				left_button={
					<IconButtonPrimary
						icon={<ImageLinks.setting_icon width={'100%'} height={'100%'} />}
						disabled
						disabled_icon={<ImageLinks.setting_icon width={'100%'} height={'100%'} />}
					/>
				}
				right_button={<NetworkIcon is_network_active={true} />}
				profile_picture={pp}
			/>
		</Box>
	);
};

const MatchMaking = () => {
	const { event_name } = useGameStore(
		useShallow((state) => ({
			event_name: state.event_name,
		})),
	);

	const is_waiting_for_opponent = event_name === GAME_CONSTANTS.EVENTS.waiting_for_opponent.name;

	useEffect(() => {
		if (!is_waiting_for_opponent) {
			router.navigate(RouteNames.Registration);
		}
	}, []);

	if (!is_waiting_for_opponent) {
		return null;
	}

	return <MatchMakingComp />;
};

export default MatchMaking;
