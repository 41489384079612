import { Howl } from 'howler';
import _ from 'lodash';

import Audio, { I_audio } from 'src/assets/audio/Audio';
import utils from 'src/utils/utils';

class Sound {
	static all_sounds: any = {};

	static init() {
		_.map(Audio, (val, key) => {
			this.all_sounds[key] = new Howl({
				src: [val],
				autoplay: false,
				loop: false,
				volume: 0.5,
				preload: true,
			});
		});
	}

	static play(key: I_audio) {
		if (this.all_sounds[key] && this.all_sounds[key].state() === 'loaded') {
			try {
				if (this.all_sounds[key].playing()) {
					this.all_sounds[key].stop();
				}

				this.all_sounds[key].play();
			} catch (err) {
				utils.capture_exception(err, 'sound error');
			}
		}
	}

	static stop(key: I_audio) {
		if (this.all_sounds[key] && this.all_sounds[key].state() === 'loaded' && this.all_sounds[key].playing()) {
			try {
				this.all_sounds[key].stop();
			} catch (err) {
				utils.capture_exception(err, 'sound stop error');
			}
		}
	}
}

export default Sound;
