import { useContext, useEffect } from 'react';
import _ from 'lodash';
import { useSprings } from '@react-spring/web';

import Card from '../../Card';
import GameContext from 'src/screens/Game/context';
import { get_starting_of_cards_pos } from 'src/screens/Game/helper';
import { GAME_CONSTANTS } from 'src/screens/Game/constants';
import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';

const OpponentCardsSection = () => {
	const { opponent_total_cards, opponent_cards_api_ref, textures } = useContext(GameContext);

	const [opponent_cards_springs, opponent_cards_api] = useSprings(opponent_total_cards, (index) => {
		const opponent_first_card_pos = get_starting_of_cards_pos(opponent_total_cards);
		return {
			from: {
				x: opponent_first_card_pos + Math.floor(index * GAME_CONSTANTS.SPACE_BW_CARDS(opponent_total_cards)),
				y: GAME_CONSTANTS.OPP_MID_CARD_POS.y,
				zIndex: GAME_CONSTANTS.TOTAL_CARDS - index,
				texture: textures[PIXI_IMAGE_LINKS.back_card1.alias],
				is_front_card: false,
			},
		};
	});

	useEffect(() => {
		opponent_cards_api_ref.current = opponent_cards_api;
	}, [opponent_cards_api]);

	return (
		<>
			{_.map(opponent_cards_springs, (card_props, index) => (
				<Card key={index} card_props={card_props} />
			))}
		</>
	);
};

export default OpponentCardsSection;
