import { useContext } from 'react';
import { Sprite } from '@pixi/react-animated';

import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';
import { GAME_CONSTANTS } from '../constants';
import GameContext from '../context';

const LineSeperator = () => {
	const { textures } = useContext(GameContext);

	return (
		<>
			<Sprite
				texture={textures[PIXI_IMAGE_LINKS.line_separator.alias]}
				x={GAME_CONSTANTS.X_CENTER}
				y={GAME_CONSTANTS.GAME_AREA_HEIGHT / 3}
				anchor={0.5}
				scale={0.4}
			/>
			<Sprite
				texture={textures[PIXI_IMAGE_LINKS.line_separator.alias]}
				x={GAME_CONSTANTS.X_CENTER}
				y={(2 * GAME_CONSTANTS.GAME_AREA_HEIGHT) / 3}
				anchor={0.5}
				scale={0.4}
			/>
		</>
	);
};

export default LineSeperator;
