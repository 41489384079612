import { useEffect } from 'react';

import SocketClass from 'src/classes/SocketClass';
import CONSTANTS from 'src/utils/constants';

const useReconnectSocketBackground = () => {
	useEffect(() => {
		const on_visibility_change = () => {
			if (document.visibilityState === 'visible' && !CONSTANTS.IS_DEV_MODE) {
				SocketClass.connect_socket(CONSTANTS.SOCKET_URL);
			}
		};

		document.addEventListener('visibilitychange', on_visibility_change);

		return () => {
			document.removeEventListener('visibilitychange', on_visibility_change);
		};
	}, []);
};

export default useReconnectSocketBackground;
