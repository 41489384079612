import _ from 'lodash';
import { Texture } from '@pixi/core';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';
import { Assets } from '@pixi/assets';

import { ALLOWED_ACTIONS, FINISH_TYPES, GAME_CONSTANTS, GAME_STATE, PICK_CARD_FROM } from 'src/screens/Game/constants';
import { Card_type } from 'src/utils/types';
import utils from 'src/utils/utils';
import CONSTANTS from 'src/utils/constants';
import SocketClass from 'src/classes/SocketClass';
import { get_opponent_id } from 'src/screens/Game/helper';
import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';
import turnNotify from '../assets/audio/turnStart.mp3';
import Sound from 'src/classes/Sound';

type texture_type = { [key: string]: Texture };

export type card_picked_ack_res_type = {
	allowedActions: ALLOWED_ACTIONS[] | [];
	ginCard: Card_type | '';
	hand: {
		meld: Card_type[][];
		hand: Card_type[];
		dw: number;
	};
	knockableCard: Card_type | '';
	pickCardFrom: PICK_CARD_FROM;
	pickedUpCard: Card_type;
	GTINumber: number;
};

export type turn_info_type = {
	turnTime: number;
	remainingTurnTime: number;
	isExtraTurnTime: boolean;
	allowedActions: ALLOWED_ACTIONS[] | [];
	knockableCard: Card_type | '';
	ginCard: Card_type | '';
};

export type I_winner_info = {
	winnerId: string;
	finishType: FINISH_TYPES;
	winningPoints: number;
	giveUndercutBonus?: boolean;
	giveKnockBonus?: boolean;
	giveRoundBonus?: boolean;
	giveBigGinBonus?: boolean;
	giveGinBonus?: boolean;
	giveDeadwoodScore?: boolean;
	winningAmount?: string;
	currencySymbol?: string;
};

type hand_type = {
	meld: Card_type[][];
	hand: Card_type[];
	dw: number;
};

// Main GTI type
export type gti_type = {
	roundNo: number;
	GTINumber: number;
	matchId: string;
	startTime: number;
	gameState: GAME_STATE;
	currentTurnUserId: string;
	unDealtCards: number;
	discardPile: Card_type[];
	gameUserId: string;
	players: {
		[key: string]: {
			username: string;
			profilePicture: string;
			lobbyId: string;
			gameUserId: string;
			activeInActiveStatus: number; // * active status if 1 and inactive if 0
			socketId: string;
			hand: hand_type;
			numberOfCards: number;
			extraTurnTimeLeft: number;
			skipTurnCount: number;
			lastPickedCardFrom: PICK_CARD_FROM | '';
			lastPickedCard: Card_type;
			matchScore: number;
		};
	};
	gameConfig: {
		turnTime: number;
		extraTurnTime: number;
		gameStartDelay: number;
		resultScreenTime: number;
		knockBonus: number;
		underCutBonus: number;
		ginBonus: number;
		bigGinBonus: number;
		dealCardDelay: number;
		maxSkipTurnAllowed: number;
		roundProgressionBonus: number;
	};
	turnInfo?: turn_info_type;
	winnerInfo?: I_winner_info;
	startNextRound?: boolean;
};

const initial_state = {
	textures: {} as texture_type,

	gti: {} as gti_type,
	event_name: '',
	event_data: {} as {
		gti?: Partial<gti_type>;
		extra?: any;
	},
	retry_loading_assets: 0,
};

type State = typeof initial_state;

type Actions = {
	set_gti: (gti: gti_type) => void;
	deal_cards: (event_name: string, event_data: any) => void;
	update_game_info: (event_name: string, event_data: any) => void;
	game_finish: (event_name: string, event_data: any) => void;
	card_picked_ack: (data: card_picked_ack_res_type) => void;
	change_opponent_status: (data: {
		gti: {
			GTINumber: number;
		};
		extra: {
			AS: number;
			gameUserId: number;
		};
	}) => void;
	get_gti_on_sequence_mismatch: (new_gti_number: number) => boolean;
	get_textures: () => void;
	pass_turn: (event_data: { gti: gti_type }, opponent_id: string) => void;
	waiting_for_opponent: (event_data: {
		remainingTime: number;
		profileInfo: {
			un: string;
			pp: string;
		};
	}) => void;
	add_match_score: (player_id: string, score_to_add: number) => void;
};

let timeout1: ReturnType<typeof setTimeout>;
let timeout2: ReturnType<typeof setTimeout>;

const store_fn = immer<State & Actions>((set, get) => ({
	..._.cloneDeep(initial_state),

	set_gti: (gti) => {
		clearTimeout(timeout1);
		clearTimeout(timeout2);
		set((state) => {
			state.gti = {
				...(state.gti || {}),
				...gti,
			};
			state.event_name = '';
			state.event_data = {};
		});
	},

	get_gti_on_sequence_mismatch: (new_gti_number) => {
		const current_gti_number = get().gti.GTINumber;

		if (new_gti_number > (current_gti_number || 0) + 1) {
			SocketClass.emit(GAME_CONSTANTS.USER_ACTIONS.GTI, {});
			utils.capture_exception(new Error('GTI sequence mismatch'));
			return true;
		}

		return false;
	},

	deal_cards: async (event_name, event_data) => {
		set((state) => {
			state.event_name = event_name;
			state.gti = event_data?.gti;
		});

		const total_animation_time = _.find(GAME_CONSTANTS.EVENTS, { name: event_name })?.total_animation_time || 0;

		timeout1 = setTimeout(() => {
			set((state) => {
				state.event_name = '';
				state.event_data = {};
				state.gti = {
					...state.gti,
					turnInfo: {
						turnTime: state.gti.gameConfig.turnTime,
						remainingTurnTime: state.gti.gameConfig.turnTime,
						isExtraTurnTime: false,
						allowedActions: [ALLOWED_ACTIONS.PASS_TURN],
						knockableCard: '',
						ginCard: '',
					},
					gameState: GAME_STATE.FIRST_TURN,
				};
			});
			// eslint-disable-next-line no-undef
			new Audio(turnNotify).play();
		}, total_animation_time + 1000);
	},

	update_game_info: async (event_name, event_data) => {
		if (get().get_gti_on_sequence_mismatch(event_data?.gti?.GTINumber)) {
			return;
		}

		set((state) => {
			state.event_name = event_name;
			state.event_data = event_data;
		});
		const total_animation_time = _.find(GAME_CONSTANTS.EVENTS, { name: event_name })?.total_animation_time || 0;

		timeout2 = setTimeout(() => {
			set((state) => {
				state.gti = {
					...(state.gti || {}),
					...(state.event_data?.gti || {}),
				};

				switch (event_name) {
					// opponent picks up the card
					case GAME_CONSTANTS.EVENTS.card_picked.name: {
						const { pickCardFrom, gameUserId } = event_data.extra as {
							gameUserId: string;
							pickCardFrom: PICK_CARD_FROM;
						};

						switch (pickCardFrom) {
							case PICK_CARD_FROM.DISCARD_PILE:
								// take out first card form array
								state.gti.discardPile = state.gti.discardPile.slice(1);
								break;

							case PICK_CARD_FROM.UN_DEALT_CARDS:
								// take out first card form array
								state.gti.unDealtCards -= 1;
								break;

							default:
								break;
						}

						state.gti.players[gameUserId].numberOfCards += 1;
						break;
					}

					// my user or opponent throws the card
					case GAME_CONSTANTS.EVENTS.card_thrown.name: {
						const { card, gameUserId, hand, finishType } = event_data.extra as {
							card: Card_type;
							gameUserId: string;
							hand?: hand_type;
							finishType?: FINISH_TYPES;
						};

						if (finishType && finishType !== FINISH_TYPES.NONE) {
							state.gti.winnerInfo = {
								finishType,
								// @todo get this value from backend
								winnerId: '',
								winningPoints: 0,
							};
							if (finishType === FINISH_TYPES.GIN) {
								state.gti.players[gameUserId].matchScore += state.gti.gameConfig.ginBonus;
							}
						}

						state.gti.discardPile.unshift(card);
						state.gti.players[gameUserId].numberOfCards -= 1;
						state.gti.players[gameUserId].lastPickedCardFrom = '';
						state.gti.players[gameUserId].lastPickedCard = '' as Card_type;
						if (hand) {
							state.gti.players[gameUserId].hand = hand;
						}

						break;
					}

					// my user or opponent throws the card
					case GAME_CONSTANTS.EVENTS.skip_card_undealt.name: {
						const { card } = event_data.extra as {
							card: Card_type;
						};

						state.gti.discardPile.unshift(card);
						state.gti.unDealtCards -= 1;
						break;
					}

					case GAME_CONSTANTS.EVENTS.big_gin.name: {
						const { gameUserId } = event_data.extra as {
							gameUserId: string;
						};

						state.gti.players[gameUserId].matchScore += state.gti.gameConfig.bigGinBonus;
						break;
					}

					default:
				}

				state.event_name = '';
				state.event_data = {};
			});
		}, total_animation_time);
	},

	card_picked_ack: async (event_data) => {
		const { allowedActions, knockableCard, ginCard, hand, GTINumber, pickCardFrom, pickedUpCard } = event_data;

		if (get().get_gti_on_sequence_mismatch(GTINumber)) {
			return;
		}

		set((state) => {
			if (!_.isEmpty(state.gti.turnInfo)) {
				state.gti.turnInfo = {
					...state.gti.turnInfo,
					allowedActions,
					knockableCard,
					ginCard,
				};
			}

			if (pickCardFrom === PICK_CARD_FROM.DISCARD_PILE) {
				// take out first card form array
				state.gti.discardPile = state.gti.discardPile.slice(1);
			}

			if (pickCardFrom === PICK_CARD_FROM.UN_DEALT_CARDS) {
				// take out first card form array
				state.gti.unDealtCards -= 1;
			}

			state.gti.players[state.gti.gameUserId].hand = hand;
			state.gti.players[state.gti.gameUserId].lastPickedCardFrom = pickCardFrom;
			state.gti.players[state.gti.gameUserId].lastPickedCard = pickedUpCard;
			state.gti.GTINumber = GTINumber;
		});
	},

	game_finish: async (event_name, event_data) => {
		set((state) => {
			clearTimeout(timeout1);
			clearTimeout(timeout2);
			state.event_name = event_name;
			state.event_data = event_data;
			state.gti.turnInfo = undefined;
			state.gti.startNextRound = event_data.gti.startNextRound;
			state.gti.GTINumber = event_data.gti.GTINumber;
		});
	},

	change_opponent_status: async (data) => {
		const gti = get().gti;

		if (!_.isEmpty(gti)) {
			const opponent_id = get_opponent_id(gti);

			set((state) => {
				state.gti.players[opponent_id].activeInActiveStatus = data.extra.AS;
				state.gti.GTINumber = data.gti.GTINumber;
			});
		}
	},

	get_textures: async () => {
		try {
			const all_textures = await Assets.load(Object.values(PIXI_IMAGE_LINKS));
			Sound.init();
			set((state) => {
				state.textures = all_textures;
			});
		} catch (err) {
			const prev_state = get();
			if (prev_state.retry_loading_assets < 3) {
				set((state) => {
					state.retry_loading_assets += 1;
				});
				prev_state.get_textures();
				return;
			}
			utils.capture_exception(err);
		}
	},

	waiting_for_opponent: (event_data) => {
		set((state) => {
			state.event_name = GAME_CONSTANTS.EVENTS.waiting_for_opponent.name;
			state.event_data.extra = event_data;
		});
	},

	pass_turn: (event_data: { gti: gti_type }, opponent_id: string) => {
		const { gti } = event_data;
		set((state) => {
			state.gti = gti;
			state.gti.turnInfo = {
				...(gti.turnInfo as NonNullable<typeof gti.turnInfo>),
				allowedActions: [],
			};
			state.gti.currentTurnUserId = opponent_id;
			state.gti.gameState = GAME_STATE.PLAY_TURN;
			state.gti.GTINumber = 2;
		});
		setTimeout(() => {
			set((state) => {
				state.event_name = GAME_CONSTANTS.EVENTS.card_picked.name;
				state.event_data = {
					gti: {},
					extra: {
						pickCardFrom: PICK_CARD_FROM.DISCARD_PILE,
						gameUserId: opponent_id,
					},
				};
			});

			setTimeout(() => {
				set((state) => {
					state.event_name = GAME_CONSTANTS.EVENTS.card_thrown.name;
					state.event_data = {
						gti: {},
						extra: {
							card: 'S13',
							gameUserId: opponent_id,
						},
					};
				});
				setTimeout(() => {
					set((state) => {
						state.gti = {
							...gti,
							turnInfo: {
								...(gti.turnInfo as NonNullable<typeof gti.turnInfo>),
								allowedActions: [ALLOWED_ACTIONS.PICK_CARD],
							},
							discardPile: ['S13'],
							currentTurnUserId: state.gti.gameUserId,
							gameState: GAME_STATE.PLAY_TURN,
							GTINumber: 3,
						};
					});
					// eslint-disable-next-line no-undef
					new Audio(turnNotify).play();
				}, 500);
			}, 1500);
		}, 1000);
	},

	add_match_score: (player_id, score_to_add) => {
		set((state) => {
			state.gti.players[player_id].matchScore += score_to_add;
		});
	},
}));

const useGameStore = CONSTANTS.IS_PROD ? create(store_fn) : create(devtools(store_fn));

export default useGameStore;
