import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import CountUp from 'react-countup';

import { GAME_CONSTANTS } from '../constants';
import GameContext from '../context';
import theme from 'src/utils/theme';
import TurnTimer from 'src/common/TurnTimer';
import ProfilePic, { RING_CHILD_VALUE } from 'src/common/ProfilePic';
import CommonUserSection from 'src/common/CommonUserSection';
import IconButtonPrimary from 'src/common/@the-source/atoms/IconButtonPrimary';
import ImageLinks from 'src/assets/images/ImageLinks';
import Text from 'src/common/@the-source/atoms/Text';
import CONSTANTS from 'src/utils/constants';

const styles: { [key: string]: React.CSSProperties } = {
	deadwood_bar_container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		width: 180,
		margin: '0 auto',
		zIndex: 0,
		top: -GAME_CONSTANTS.USER_SECTION_HEIGHT + 47,
		right: '10%',
	},
	text: {
		color: theme.colors.white,
		fontSize: 12,
		position: 'absolute',
		top: '11px',
	},
	text_left: {
		left: '5%',
	},
	text_right: {
		right: '14%',
		top: '3px',
	},
	profile_container: {
		position: 'absolute',
		right: '10%',
		top: '25%',
		zIndex: 10,
	},
};

const OpponentSection = () => {
	const { gti, opponent_id, handle_exit_in_game, opponent_match_score, event_name } = useContext(GameContext);

	const [old_match_score, set_old_match_score] = React.useState(opponent_match_score);

	const turn_info = gti.currentTurnUserId === opponent_id ? gti.turnInfo : undefined;

	const { skipTurnCount } = gti.players[opponent_id];

	useEffect(() => {
		if (old_match_score !== opponent_match_score) {
			let timeout = setTimeout(() => {
				set_old_match_score(opponent_match_score);
			}, 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [opponent_match_score]);

	return (
		<CommonUserSection is_opponent={true}>
			<Box sx={styles.profile_container}>
				<ProfilePic
					ring_child={{
						value: RING_CHILD_VALUE.PROFILE_PIC,
						onClick: _.noop,
					}}
					profile_picture='https://d29i2jg7pajoz0.cloudfront.net/avatars/8.png'
				/>
				{_.map(
					[
						{
							left: '0%',
							top: '-4%',
							transform: 'rotate(142deg)',
						},

						{
							right: '33%',
							top: '-14%',
							transform: 'rotate(180deg)',
						},
						{
							right: '0%',
							top: '-4%',
							transform: 'rotate(218deg)',
						},
					],
					(val, key) => {
						return (
							<Box key={`skip_${key}`} sx={{ position: 'absolute', ...val }}>
								{key < skipTurnCount ? <ImageLinks.skip_red /> : <ImageLinks.skip />}
							</Box>
						);
					},
				)}
			</Box>

			<Box
				sx={{
					position: 'absolute',
					right: 110,
					marginTop: 20,
					backgroundImage: `url(${ImageLinks.score_bg})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					width: 170,
					height: 50,
					paddingLeft: 20,
					display: 'flex',
					alignItems: 'center',
				}}>
				<Box sx={{ width: 110, textAlign: 'center' }}>
					<Text
						pridi
						medium
						sx={{
							fontSize: 18,
							background: `linear-gradient(to right, ${theme.colors.putty} 18%, ${theme.colors.lemon_chiffon} 52.7%, ${theme.colors.putty} 86.83%)`,
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
						}}>
						<Text pridi medium component={'span'} sx={{ fontSize: 36 }}>
							<CountUp start={old_match_score} end={opponent_match_score} duration={1} />/
						</Text>
						100
					</Text>
				</Box>
			</Box>

			{!_.isEmpty(turn_info) && (
				<Box sx={[{ position: 'absolute', width: CONSTANTS.WIDTH * 0.9 - 110, bottom: 0, left: 0 }]}>
					<TurnTimer turn_info={turn_info} />
				</Box>
			)}
			{event_name !== GAME_CONSTANTS.EVENTS.game_result_screen.name && (
				<IconButtonPrimary
					onClick={handle_exit_in_game}
					icon={<ImageLinks.exit_matchmaking_icon width={'100%'} height={'100%'} />}
					container_styles={{ position: 'absolute', left: '10%', top: '20%' }}
				/>
			)}
		</CommonUserSection>
	);
};

export default OpponentSection;
