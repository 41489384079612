import { Container, Sprite } from '@pixi/react-animated';
import { ITextStyle } from '@pixi/text';
import { SpringValue } from '@react-spring/web';
import { Texture } from '@pixi/core';

import PixiText from 'src/common/@the-source/atoms/PixiText';
import { GAME_CONSTANTS } from 'src/screens/Game/constants';
import theme from 'src/utils/theme';

interface Props {
	score: string;
	x: number | SpringValue;
	y: number | SpringValue;
	texture: Texture | SpringValue;
	width: number | SpringValue;

	opacity?: number | SpringValue;
	styles?: Partial<ITextStyle>;
}

const ScoreBox = ({ texture, score, x, y, styles = {}, opacity = 1, width }: Props) => {
	return (
		<Container sortableChildren={true} alpha={opacity} zIndex={GAME_CONSTANTS.VERY_BIG_ZINDEX}>
			<Sprite x={x} y={y} texture={texture as Texture} anchor={0.5} width={width} height={width} />
			<PixiText
				key={score}
				text={score}
				x={x}
				y={y}
				styles={{
					align: 'center',
					fontSize: 0.4 * GAME_CONSTANTS.GAME_FINISH.score_box.score_box_width,
					fontWeight: 'normal',
					fill: theme.colors.white,
					...styles,
				}}
			/>
		</Container>
	);
};

export default ScoreBox;
