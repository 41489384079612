import { Box } from '@mui/material';
import { easings, useSpring, animated } from '@react-spring/web';
import React from 'react';

import ImageLinks from 'src/assets/images/ImageLinks';
import Text from './@the-source/atoms/Text';
import theme from 'src/utils/theme';

const styles = {
	container: {
		background: `url(${ImageLinks.user_section_bg})`,
		backgroundSize: 'cover',
		position: 'relative',
	},
	profile_buttons_container: {
		height: 110,
		width: 110,
		margin: '0 auto',
		backgroundColor: theme.colors.bleached_cedar,
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	btn_container: {
		width: '100%',
		height: '100%',
		cursor: 'pointer',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		borderRadius: '100%',
		position: 'relative',
	},
	btn_text: {
		position: 'relative',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 10,
		textAlign: 'center',
		fontSize: '2.25rem',
	},
	profile_pic: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: '100%',
	},
};

export enum RING_CHILD_VALUE {
	PROFILE_PIC = 'PROFILE_PIC',
	PASS_BUTTON = 'PASS_BUTTON',
	KNOCK_BUTTON = 'KNOCK_BUTTON',
	GIN_BUTTON = 'GIN_BUTTON',
	BIG_GIN_BUTTON = 'BIG_GIN_BUTTON',
}

interface Props {
	ring_child: {
		value: RING_CHILD_VALUE;
		onClick: () => void;
	};

	container_styles?: React.CSSProperties;
	profile_picture?: string;
}

const ActionButton = ({ text }: { text: string }) => {
	const animation_props = useSpring({
		from: { opacity: 0, transform: 'scale(0.5)' },
		to: { opacity: 1, transform: 'scale(1)' },
		config: { duration: 1_000, easing: easings.easeOutBounce },
	});

	return (
		<Box sx={styles.btn_container}>
			<animated.div style={{ ...animation_props, width: '100%', height: '100%' }}>
				<ImageLinks.game_button width='100%' height='100%' style={{ position: 'absolute' }} />
				<Text sx={styles.btn_text}>{text}</Text>
			</animated.div>
		</Box>
	);
};

const ProfilePic = ({ ring_child, container_styles = {}, profile_picture }: Props) => {
	const show_comp = () => {
		let text = '';
		switch (ring_child.value) {
			case RING_CHILD_VALUE.PROFILE_PIC:
				return (
					<Box sx={styles.btn_container}>
						{profile_picture ? (
							<Box sx={styles.profile_pic}>
								<img src={profile_picture} width={'100%'} height={'100%'} style={{ borderRadius: '100%' }} />
							</Box>
						) : (
							<ImageLinks.profile_placeholder width={'100%'} height={'100%'} />
						)}
					</Box>
				);

			case RING_CHILD_VALUE.PASS_BUTTON:
				text = 'Pass';
				break;

			case RING_CHILD_VALUE.GIN_BUTTON:
				text = 'Gin';
				break;

			case RING_CHILD_VALUE.BIG_GIN_BUTTON:
				text = 'Big Gin';
				break;

			case RING_CHILD_VALUE.KNOCK_BUTTON:
				text = 'Knock';
				break;

			default:
				break;
		}

		return <ActionButton text={text} />;
	};

	return (
		<Box sx={[styles.profile_buttons_container, container_styles]}>
			<img src={ImageLinks.ring} height={'100%'} style={{ position: 'absolute' }} />
			<Box sx={{ height: '90%', width: '90%', borderRadius: '100%' }} onClick={ring_child.onClick}>
				{show_comp()}
			</Box>
		</Box>
	);
};

export default ProfilePic;
