import { Box } from '@mui/material';
import React from 'react';

import ImageLinks from 'src/assets/images/ImageLinks';
import { GAME_CONSTANTS } from 'src/screens/Game/constants';

const styles = {
	container: {
		background: `url(${ImageLinks.user_section_bg})`,
		backgroundSize: 'cover',
		position: 'relative', // Ensures absolute children (pointer) are positioned relative to this container
		height: GAME_CONSTANTS.USER_SECTION_HEIGHT,
		zIndex: 2,
	},
	pointer: {
		position: 'absolute',
		left: '25%',
		bottom: 120,
		animation: 'pointerMovement 1s infinite 1s',
		zIndex: 100,
		transform: 'rotate(230deg)',
	},
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		transition: '1s',
		zIndex: 1,
	},
};

interface Props {
	children: React.ReactNode;
	is_opponent?: boolean;
	show_grey_overlay?: boolean; // Optional prop for grey overlay
	showPointerOnButton?: boolean; // Optional prop for pointer image
}

const CommonUserSection = ({ children, is_opponent = false, show_grey_overlay = false, showPointerOnButton = false }: Props) => {
	return (
		<Box sx={styles.container}>
			{show_grey_overlay && <Box sx={styles.overlay} />}

			{/* Dashed lines */}
			<ImageLinks.dashed_line width={'100%'} height={2} style={{ position: 'absolute', top: 5 }} />
			<ImageLinks.dashed_line width={'100%'} height={2} style={{ position: 'absolute', bottom: 5 }} />

			{/* Buttons on left and right */}
			<Box
				component={'img'}
				src={ImageLinks.buttons}
				height={20}
				sx={[{ position: 'absolute', left: 7 }, is_opponent ? { bottom: 15 } : { top: 15 }]}
			/>
			<Box
				component={'img'}
				src={ImageLinks.buttons}
				height={20}
				sx={[{ position: 'absolute', right: 7 }, is_opponent ? { bottom: 15 } : { top: 15 }]}
			/>

			{/* Conditional rendering of pointer image */}
			{showPointerOnButton && <Box component={'img'} src={ImageLinks.pointer} height={70} width={70} sx={styles.pointer} />}

			{/* Children components */}
			{children}
		</Box>
	);
};

export default CommonUserSection;
