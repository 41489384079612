import { Typography, TypographyProps } from '@mui/material';
import theme from 'src/utils/theme';

interface Props extends TypographyProps {
	bold?: boolean;
	semi?: boolean;
	medium?: boolean;
	mulish?: boolean;
	pridi?: boolean;
}

const Text = ({ bold, semi, medium, children, mulish, pridi, sx = {}, ...rest }: Props) => {
	let font_family = theme.fonts.brawler_regular;

	if (mulish) {
		font_family = theme.fonts.mulish_regular;
	}

	if (pridi) {
		font_family = theme.fonts.pridi_regular;
	}

	if (medium) {
		if (mulish) {
			font_family = theme.fonts.mulish_medium;
		}
		if (pridi) {
			font_family = theme.fonts.pridi_medium;
		}
	}

	if (bold) {
		font_family = theme.fonts.brawler_bold;

		if (mulish) {
			font_family = theme.fonts.mulish_bold;
		}
		if (pridi) {
			font_family = theme.fonts.pridi_bold;
		}
	}

	if (semi) {
		if (mulish) {
			font_family = theme.fonts.mulish_semibold;
		}
	}

	return (
		<Typography
			sx={{
				textTransform: 'initial',
				fontFamily: font_family,
				...sx,
			}}
			{...rest}>
			{children}
		</Typography>
	);
};

export default Text;
