import { createTheme } from '@mui/material';

const basicTheme = createTheme({
	spacing: 1,
});

const theme = {
	colors: {
		white: '#ffffff',
		white6: '#ffffff99',

		black: '#000000',
		black5: '#00000080',
		black1: '#0f0f0f',

		lotus: '#833737',
		matrix: '#B15252',
		russet: '#7F5D5D',
		eclipse: '#3E1C1C',

		hippie_green: '#588E4F',
		amulet: '#85AC7F',
		chalet_green: '#466C3F',
		equator: '#E4BD60',
		peach: '#FFE9B2',

		bottle_green: '#072D1B',
		fun_green: '#005D37',
		black_bean: '#091B10',
		cod_gray: '#151515',
		flint: '#6b685f',
		chalky: '#ECCE89',
		chalky2: '#ECCF8A',
		egg_white: '#FFEFC5',
		driftwood: '#AC904B',
		driftwood2: '#AC8F4A',
		indian_khaki: '#BFAF85',

		bleached_cedar: '#1F1828',
		putty: '#E1D682',
		lemon_chiffon: '#FFFBCB',

		jacarta: '#39336D',
		de_york: '#8FC686',
		reef: '#B8FFAC',

		apricot: '#EC7D7D',
		alto: '#DBDBDB',
	},
	fonts: {
		brawler_bold: 'Brawler-Bold',
		brawler_regular: 'Brawler-Regular',

		mulish_bold: 'Mulish-Bold',
		mulish_medium: 'Mulish-Medium',
		mulish_regular: 'Mulish-Regular',
		mulish_semibold: 'Mulish-SemiBold',

		pridi_bold: 'Pridi-Bold',
		pridi_medium: 'Pridi-Medium',
		pridi_regular: 'Pridi-Regular',
	},
	...basicTheme,
};

export default theme;
