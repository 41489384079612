import React from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import ImageLinks from 'src/assets/images/ImageLinks';

type Props = {
	icon: React.ReactNode;

	clickable?: boolean;
	onClick?: () => void;
	container_styles?: any;
	disabled_icon?: React.ReactNode;
	disabled?: boolean;
};

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		width: 40,
		height: 40,
	},
	sub_container: {
		width: '90%',
		height: '90%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
	},
	btn_animate: {
		':active': {
			transform: 'translateY(2px)',
		},
	},
	outer_img: {
		position: 'absolute',
		width: '100%',
		height: '100%',
	},
	outer_img_2: {
		position: 'absolute',
		width: '90%',
		height: '90%',
	},
};

const IconButtonSecondary = ({
	container_styles = {},
	onClick = _.noop,
	clickable = true,
	icon,
	disabled = false,
	disabled_icon,
}: Props) => {
	const handle_click = () => {
		if (clickable && !disabled) {
			onClick();
		}
	};

	const Bg_img = disabled ? ImageLinks.button_ring_grey : ImageLinks.button_ring;

	return (
		<Box sx={[styles.container, container_styles]} onClick={handle_click}>
			<Box component={'img'} src={ImageLinks.circle_button_outer} sx={styles.outer_img} />
			<Box sx={[styles.sub_container, clickable && !disabled ? styles.btn_animate : {}]}>
				<Box sx={styles.outer_img_2}>
					<Bg_img width='100%' height='100%' />
				</Box>
				<Box sx={{ zIndex: 1, width: '45%', height: '45%' }}>{disabled ? disabled_icon : icon}</Box>
			</Box>
		</Box>
	);
};

export default IconButtonSecondary;
