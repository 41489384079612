import axios from 'axios';

const ALL_ENV = {
	staging: 'staging',
	production: 'production',
	gm: 'gm',
};

const CURRENT_ENV = ALL_ENV.staging;

let GAME_API_URL = '';
let SOCKET_URL = '';

switch (CURRENT_ENV) {
	case ALL_ENV.staging: {
		GAME_API_URL = 'https://api-staging.toaststudios.io/';
		// @todo get this url from backend in future for infra changes. old games should work with old infra and new games with new infra
		SOCKET_URL = 'https://api-staging-gin-rummy.toaststudios.io/';
		break;
	}

	case ALL_ENV.production: {
		GAME_API_URL = 'https://api.toaststudios.io/';
		SOCKET_URL = 'https://api-gin-rummy.toaststudios.io/';
		break;
	}

	case ALL_ENV.gm: {
		GAME_API_URL = 'https://gm-staging.toaststudios.io/';
		SOCKET_URL = 'https://gm-gin-rummy.toaststudios.io/';
		break;
	}

	default:
		break;
}

axios.defaults.baseURL = GAME_API_URL;

const CONSTANTS = {
	CURRENT_ENV,
	ALL_ENV,
	GAME_API_URL,
	SOCKET_URL,
	IS_DEV_MODE: import.meta.env.DEV,
	IS_PROD: CURRENT_ENV === ALL_ENV.production,
	WIDTH: window.innerWidth <= 600 ? window.innerWidth : 500,
	WEBVIEW_EVENTS: {
		quit: 'quit',
	},
	IS_FREEWIN: () => (window as any)?.webview_data?.is_freewin,
};

export default CONSTANTS;
