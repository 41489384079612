import { createBrowserRouter } from 'react-router-dom';

import Home from '../screens/HomeTest/HomeTest';
import Game from '../screens/Game/Game';
import MatchMaking from 'src/screens/MatchMaking/MatchMaking';
import CONSTANTS from './constants';
import Registration from 'src/screens/Registration/Registration';
import { FirstInteraction } from 'src/screens/FirstInteraction/FirstInteraction';

export const RouteNames = {
	HomeTest: '/home-test',
	Game: '/game',
	Matchmaking: '/matchmaking',
	Registration: '/registration',
	default: '/',
};

const routes = [
	{
		path: RouteNames.Matchmaking,
		element: <MatchMaking />,
	},
	{
		path: RouteNames.Game,
		element: <Game />,
	},
	{
		path: RouteNames.Registration,
		element: <Registration />,
	},
	{
		path: RouteNames.default,
		element: <FirstInteraction />,
	},
];

if (CONSTANTS.CURRENT_ENV !== CONSTANTS.ALL_ENV.production) {
	routes.push({
		path: RouteNames.HomeTest,
		element: <Home />,
	});
}

export const router = createBrowserRouter(routes);
