import { useShallow } from 'zustand/react/shallow';
import Lottie from 'lottie-react';
import { Box, Modal } from '@mui/material';

import useApp from 'src/store/useApp';
import theme from 'src/utils/theme';
import Animations from 'src/assets/animations/Animations';

const styles = {
	wrapper: {
		display: 'flex',
		backgroundColor: theme.colors.black5,
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
	},
};

const Loader = () => {
	const { is_loading } = useApp(
		useShallow((state) => ({
			is_loading: state.is_loading,
		})),
	);

	return (
		<Modal open={is_loading} closeAfterTransition>
			<Box sx={styles.wrapper}>
				<Lottie animationData={Animations.loader} loop autoplay style={{ width: 85, height: 85 }} />
			</Box>
		</Modal>
	);
};

export default Loader;
