import IconButtonSecondary from './@the-source/atoms/IconButtonSecondary';
import theme from 'src/utils/theme';

//removed status props for hard coded FTUE

const NetworkSignalIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 22 16' fill='none'>
			<path
				fill={theme.colors.de_york}
				d='M1.06 6.06c.51.51 1.32.56 1.87.1 4.67-3.84 11.45-3.84 16.13-.01.56.46 1.38.42 1.89-.09.59-.59.55-1.57-.1-2.1-5.71-4.67-13.97-4.67-19.69 0-.65.52-.7 1.5-.1 2.1Z'
			/>
			<path
				fill={theme.colors.de_york}
				d='M5.09 10.09c.49.49 1.26.54 1.83.13a7.064 7.064 0 0 1 8.16 0c.57.4 1.34.36 1.83-.13l.01-.01c.6-.6.56-1.62-.13-2.11-3.44-2.49-8.13-2.49-11.58 0-.69.5-.73 1.51-.12 2.12Z'
			/>
			<path
				fill={theme.colors.de_york}
				d='M8.82 13.82l1.47 1.47c.39.39 1.02.39 1.41 0l1.47-1.47c.47-.47.37-1.28-.23-1.59a4.28 4.28 0 0 0-3.91 0c-.57.31-.68 1.12-.21 1.59Z'
			/>
		</svg>
	);
};

const NetworkIcon = ({ is_network_active = false, container_styles }: { is_network_active?: boolean; container_styles?: any }) => {
	return (
		<IconButtonSecondary
			clickable={false}
			disabled={!is_network_active}
			icon={<NetworkSignalIcon />}
			container_styles={container_styles}
		/>
	);
};

export default NetworkIcon;
