import { useShallow } from 'zustand/react/shallow';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimationItem } from 'lottie-web';

import SocketClass from 'src/classes/SocketClass';
import { ALLOWED_ACTIONS, FINISH_TYPES, GAME_CONSTANTS, PICK_CARD_FROM } from './constants';
import useGameStore, { card_picked_ack_res_type } from 'src/store/useGameStore';
import { RouteNames } from 'src/utils/navigation';
import { get_final_position_my_user, get_my_user_card_positions, get_opponent_id, get_starting_of_cards_pos } from './helper';
import utils from 'src/utils/utils';
import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';
import useApp from 'src/store/useApp';
import Animations from 'src/assets/animations/Animations';
import turnNotify from '../../assets/audio/turnStart.mp3';
import Sound from 'src/classes/Sound';

const initial_lottie_modal_details = {
	is_open: false,
	lottie_animation: null as AnimationItem | null,
	on_close: _.noop,
	text_to_show: undefined as string | undefined,
};

const initial_counter_modal_details = {
	is_open: false,
	on_close: _.noop,
};

const useGame = () => {
	const { gti, textures, event_name, event_data, card_picked_ack, update_game_info } = useGameStore(
		useShallow((state) => ({
			gti: state.gti,
			textures: state.textures,
			event_name: state.event_name,
			event_data: state.event_data,
			card_picked_ack: state.card_picked_ack,
			update_game_info: state.update_game_info,
		})),
	);

	const [show_next_round_starting, set_show_next_round_starting] = useState(false);

	const [lottie_modal_details, set_lottie_modal_details] = useState(_.cloneDeep(initial_lottie_modal_details));
	const [counter_modal_details, set_counter_modal_details] = useState(_.cloneDeep(initial_counter_modal_details));

	const [show_replay_screen, set_show_replay_screen] = useState(false);
	const [my_user_match_score, set_my_user_match_score] = useState(0);

	const { show_generic_modal } = useApp(
		useShallow((state) => ({
			show_generic_modal: state.show_generic_modal,
		})),
	);
	const navigate = useNavigate();
	const { gameUserId, currentTurnUserId, turnInfo, gameConfig } = gti;

	const { ginBonus, bigGinBonus } = gameConfig;

	const my_user_id = gameUserId;
	const opponent_id = get_opponent_id(gti);

	const is_no_data = _.isEmpty(gti);

	const { profilePicture: my_profile_picture } = _.get(gti, `players[${my_user_id}]`) || {};
	const { matchScore: opponent_match_score } = _.get(gti, `players[${opponent_id}]`) || {};

	const { hand: my_user_hand = [], meld: my_user_meld = [] } = _.get(gti, `players[${my_user_id}].hand`) || {};

	const { my_user_card_positions, my_user_combined_cards } = useMemo(() => {
		return {
			my_user_card_positions: get_my_user_card_positions(my_user_hand, my_user_meld),
			my_user_combined_cards: [...my_user_hand, ..._.flatten(my_user_meld)],
		};
	}, [my_user_hand, my_user_meld]);

	const closed_deck_cards_count = gti.unDealtCards;
	const discard_pile_cards = gti.discardPile;

	const back_card = PIXI_IMAGE_LINKS.back_card1.alias;

	const opponent_total_cards = gti.players[opponent_id].numberOfCards;

	const my_user_cards_api_ref = useRef<any>(null);
	const opponent_cards_api_ref = useRef<any>(null);
	const discard_pile_cards_api_ref = useRef<any>(null);
	const closed_deck_cards_api_ref = useRef<any>(null);
	const deadwood_bar_api_ref = useRef<any>(null);

	const handle_my_user_pick_card = (pick_from: PICK_CARD_FROM) => {
		return new Promise((resolve, reject) => {
			if (currentTurnUserId === my_user_id && _.includes(turnInfo?.allowedActions, ALLOWED_ACTIONS.PICK_CARD)) {
				// SocketClass.emit(
				// GAME_CONSTANTS.USER_ACTIONS.PICK_CARD,
				// {
				// 	pickFrom: pick_from,
				// },
				(async (res: { data: card_picked_ack_res_type; error: boolean; message: string }) => {
					if (res.error) {
						reject(res.message);
						return;
					}

					const {
						hand: { hand: my_user_new_deadwood, meld: my_user_new_meld },
						pickedUpCard,
						pickCardFrom,
					} = res.data;

					const new_positions = get_final_position_my_user(my_user_new_deadwood, my_user_new_meld);

					const picked_pile_ref =
						pickCardFrom === PICK_CARD_FROM.DISCARD_PILE ? discard_pile_cards_api_ref : closed_deck_cards_api_ref;

					picked_pile_ref.current?.start((i: number) => {
						if (i === 0) {
							return {
								to: [
									{
										is_front_card: true,
										zIndex: new_positions[pickedUpCard].zIndex,
										texture: textures[pickedUpCard],
										config: {
											duration: 0,
										},
									},
									{
										x: new_positions[pickedUpCard].x,
										y: new_positions[pickedUpCard].y,
									},
								],
								config: {
									duration: GAME_CONSTANTS.EVENTS.card_picked.animation_time.card_pick_duration,
								},
							};
						}
					});

					my_user_cards_api_ref.current?.start((i: number) => {
						return {
							to: [
								{
									x: new_positions[my_user_combined_cards[i]].x,
									y: new_positions[my_user_combined_cards[i]].y,
									zIndex: new_positions[my_user_combined_cards[i]].zIndex,
								},
							],
							config: {
								duration: GAME_CONSTANTS.EVENTS.card_picked.animation_time.card_pick_duration,
							},
						};
					});

					await utils.delay(GAME_CONSTANTS.EVENTS.card_picked.animation_time.card_pick_duration);
					card_picked_ack(res.data);
					resolve(null);
				})(
					pick_from === PICK_CARD_FROM.DISCARD_PILE
						? {
								data: {
									hand: {
										meld: [
											['C4', 'C5', 'C6'],
											['S11', 'S12', 'S13'],
										],
										hand: ['H1', 'H2', 'H5', 'D7', 'C13'],
										dw: 25,
									},
									pickedUpCard: 'S13',
									pickCardFrom: pick_from,
									allowedActions: [ALLOWED_ACTIONS.DISCARD_CARD],
									knockableCard: '',
									ginCard: '',
									GTINumber: 4,
								},
								error: false,
								message: '',
							}
						: {
								data: {
									hand: {
										meld: [
											['H1', 'H2', 'H3'],
											['C4', 'C5', 'C6'],
											['S11', 'S12', 'S13'],
										],
										hand: ['H5', 'D7'],
										dw: 12,
									},
									pickedUpCard: 'H3',
									pickCardFrom: pick_from,
									allowedActions: [ALLOWED_ACTIONS.KNOCK],
									knockableCard: 'D7',
									ginCard: '',
									GTINumber: 7,
								},
								error: false,
								message: '',
							},
				);
				// );
			}
		});
	};

	const handle_my_user_card_come_back = (index: number) => {
		my_user_cards_api_ref.current.start((i: number) => {
			return {
				to:
					i === index
						? [
								{
									x: my_user_card_positions[index].x,
									y: my_user_card_positions[index].y,
								},
							]
						: [],
				config: {
					duration: 500,
				},
			};
		});
	};

	const handle_my_user_throw_card = (x: number, y: number, index: number, drag_start_pos: { x: number; y: number }) => {
		my_user_cards_api_ref.current?.set((i: number) => {
			if (i === index) {
				return {
					x,
					y,
				};
			}
			return {};
		});

		if (Math.abs(y - drag_start_pos.y) < (GAME_CONSTANTS.MY_USER_MID_CARD_POS.y - GAME_CONSTANTS.DISCARD_PILE_POS.y) / 2) {
			handle_my_user_card_come_back(index);
			return;
		}

		if (currentTurnUserId === my_user_id && _.includes(turnInfo?.allowedActions, ALLOWED_ACTIONS.DISCARD_CARD)) {
			// SocketClass.emit(
			// 	GAME_CONSTANTS.USER_ACTIONS.DROP_CARD,
			// 	{
			// 		card: my_user_combined_cards[index],
			// 	},
			// 	(res) => {
			// 		if (res.error) {
			// 			handle_my_user_card_come_back(index);
			// 		}
			// 	},
			// );
			update_game_info(GAME_CONSTANTS.EVENTS.card_thrown.name, {
				extra: {
					card: 'C13',
					gameUserId: my_user_id,
					hand: {
						meld: [
							['C4', 'C5', 'C6'],
							['S11', 'S12', 'S13'],
						],
						hand: ['H1', 'H2', 'H5', 'D7'],
						dw: 15,
						finishType: 'NONE',
					},
				},
				gti: {
					GTINumber: 5,
					turnInfo: {
						...gti.turnInfo,
						allowedActions: [],
					},
					currentTurnUserId: opponent_id,
				},
			});
			setTimeout(() => {
				update_game_info(GAME_CONSTANTS.EVENTS.card_picked.name, {
					extra: {
						pickCardFrom: PICK_CARD_FROM.UN_DEALT_CARDS,
						gameUserId: opponent_id,
					},
					gti: {
						GTINumber: 5,
					},
				});

				setTimeout(() => {
					update_game_info(GAME_CONSTANTS.EVENTS.card_thrown.name, {
						extra: {
							card: 'C11',
							gameUserId: opponent_id,
							finishType: 'NONE',
						},
						gti: {
							GTINumber: 5,
						},
					});
					setTimeout(() => {
						update_game_info(GAME_CONSTANTS.EVENTS.player_turn_info.name, {
							extra: {},
							gti: {
								GTINumber: 6,
								turnInfo: {
									...gti.turnInfo,
									allowedActions: [ALLOWED_ACTIONS.PICK_CARD],
									currentTurnUserId: my_user_id,
								},
								currentTurnUserId: my_user_id,
							},
						});
						// eslint-disable-next-line no-undef
						new Audio(turnNotify).play();
					}, 500);
				}, 1500);
			}, 1000);
		} else {
			handle_my_user_card_come_back(index);
		}
	};

	const handle_knock_gin_biggin = (action: ALLOWED_ACTIONS) => {
		if (currentTurnUserId === my_user_id && _.includes(turnInfo?.allowedActions, action)) {
			let user_action = '';
			switch (action) {
				case ALLOWED_ACTIONS.KNOCK:
					user_action = GAME_CONSTANTS.USER_ACTIONS.KNOCK;
					break;

				case ALLOWED_ACTIONS.GIN:
					user_action = GAME_CONSTANTS.USER_ACTIONS.GIN;
					break;

				case ALLOWED_ACTIONS.BIG_GIN:
					user_action = GAME_CONSTANTS.USER_ACTIONS.BIG_GIN;
					break;

				default:
					break;
			}

			SocketClass.emit(user_action, {});
		}
	};

	const handle_exit_in_game = () => {
		show_generic_modal({
			title: 'Quit now & lose',
			sub_title: 'Are you sure?',
			secondary_btn_text: 'Quit',
			primary_btn_text: 'Stay',
			on_secondary_press: () => {
				SocketClass.emit(GAME_CONSTANTS.USER_ACTIONS.LEAVE_IN_GAME, {});
			},
		});
	};

	const handle_open_lottie_modal = (lottie_animation: AnimationItem, on_close = _.noop, text_to_show?: string) => {
		set_lottie_modal_details({
			lottie_animation,
			is_open: true,
			on_close,
			text_to_show,
		});
	};

	const handle_open_counter_modal = () => {
		set_counter_modal_details({
			is_open: true,
			on_close: _.noop,
		});
	};
	const handle_counter_modal_close = () => {
		set_counter_modal_details({
			...counter_modal_details,
			is_open: false,
		});
	};

	const handle_lottie_modal_close = () => {
		set_lottie_modal_details({
			...lottie_modal_details,
			is_open: false,
		});
	};

	const handle_show_next_round_starting = () => {
		set_show_next_round_starting(true);
	};

	const handle_show_replay_screen = () => {
		//hard coded for FTUE (changed true to false)
		set_show_replay_screen(false);
	};

	useEffect(() => {
		if (is_no_data) {
			navigate(RouteNames.default);
		}
	}, []);

	useEffect(() => {
		switch (event_name) {
			case GAME_CONSTANTS.EVENTS.dealt_card.name: {
				set_show_next_round_starting(false);
				break;
			}

			case GAME_CONSTANTS.EVENTS.big_gin.name: {
				handle_open_lottie_modal(Animations.big_gin, _.noop, `+${bigGinBonus}`);
				Sound.play('bonus');
				break;
			}

			case GAME_CONSTANTS.EVENTS.card_picked.name:
				Sound.play('pick_card');
				// opponent card picked animation from dealt or undealt

				const card_picked_from = event_data.extra.pickCardFrom as PICK_CARD_FROM;
				let new_total_opponent_cards = opponent_total_cards + 1;
				let opponent_new_first_card_pos = get_starting_of_cards_pos(new_total_opponent_cards);

				// card goes from discard_pile to opponent at last position
				const card_picked_from_api =
					card_picked_from === PICK_CARD_FROM.DISCARD_PILE ? discard_pile_cards_api_ref : closed_deck_cards_api_ref;

				card_picked_from_api.current?.start((index: number) => {
					if (index === 0) {
						return {
							to: [
								{
									x:
										opponent_new_first_card_pos +
										Math.floor(10 * GAME_CONSTANTS.SPACE_BW_CARDS(new_total_opponent_cards)),
									y: GAME_CONSTANTS.OPP_MID_CARD_POS.y,
									angle: 0,
									texture: textures[back_card],
									is_front_card: false,
								},
							],
							config: {
								duration: GAME_CONSTANTS.EVENTS.card_picked.animation_time.card_pick_duration,
							},
						};
					}
				});

				//opponent's cards rearrange animation
				opponent_cards_api_ref.current?.start((index: number) => {
					return {
						to: [
							{
								x:
									opponent_new_first_card_pos +
									Math.floor(index * GAME_CONSTANTS.SPACE_BW_CARDS(new_total_opponent_cards)),
								y: GAME_CONSTANTS.OPP_MID_CARD_POS.y,
								zIndex: GAME_CONSTANTS.TOTAL_CARDS - index,
								texture: textures[back_card],
								is_front_card: false,
							},
						],
						config: {
							duration: GAME_CONSTANTS.EVENTS.card_picked.animation_time.card_pick_duration,
						},
					};
				});
				break;

			case GAME_CONSTANTS.EVENTS.card_thrown.name:
				{
					const finish_type = event_data.extra.finishType;
					const is_gin_or_knock = finish_type === FINISH_TYPES.GIN || finish_type === FINISH_TYPES.KNOCK;

					switch (finish_type) {
						case FINISH_TYPES.KNOCK:
							Sound.play('knock');
							break;
						case FINISH_TYPES.GIN:
							Sound.play('bonus');
							handle_open_lottie_modal(Animations.gin, _.noop, `+${ginBonus}`);
							break;
						default:
							Sound.play('card_drop');
							break;
					}

					if (event_data.extra.gameUserId === my_user_id) {
						const new_positions = get_final_position_my_user(event_data.extra.hand.hand, event_data.extra.hand.meld);
						my_user_cards_api_ref.current?.start((i: number) => {
							if (my_user_combined_cards[i] === event_data.extra.card) {
								return {
									to: [
										{
											x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
											y: GAME_CONSTANTS.DISCARD_PILE_POS.y,
											texture: textures[is_gin_or_knock ? back_card : event_data.extra.card],
										},
									],
									config: {
										duration: GAME_CONSTANTS.EVENTS.card_thrown.animation_time.card_drop_duration,
									},
								};
							}

							return {
								to: [
									{
										x: new_positions[my_user_combined_cards[i]].x,
										y: new_positions[my_user_combined_cards[i]].y,
									},
								],
								config: {
									duration: GAME_CONSTANTS.EVENTS.card_thrown.animation_time.card_drop_duration,
								},
							};
						});
					} else {
						switch (finish_type) {
							case FINISH_TYPES.KNOCK:
								handle_open_lottie_modal(Animations.opponent_knock);
								break;
							default:
								break;
						}
						//opponent's first card goes to discard_pile
						const card_thrown = event_data.extra.card;
						const cards_remaining_after_throw = opponent_total_cards - 1;
						const new_first_card_pos_after_throw = get_starting_of_cards_pos(cards_remaining_after_throw);

						opponent_cards_api_ref.current?.start((index: number) => {
							if (index === 0) {
								return {
									to: [
										{
											x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
											y: GAME_CONSTANTS.DISCARD_PILE_POS.y,
											texture: textures[is_gin_or_knock ? back_card : card_thrown],
											is_front_card: true,
										},
									],
									config: {
										duration: GAME_CONSTANTS.EVENTS.card_thrown.animation_time.card_drop_duration,
									},
								};
							}

							return {
								to: [
									{
										x:
											new_first_card_pos_after_throw +
											Math.floor((index - 1) * GAME_CONSTANTS.SPACE_BW_CARDS(cards_remaining_after_throw)),
										y: GAME_CONSTANTS.OPP_MID_CARD_POS.y,
										zIndex: GAME_CONSTANTS.TOTAL_CARDS - index,
									},
								],
								config: {
									duration: GAME_CONSTANTS.EVENTS.card_thrown.animation_time.card_drop_duration,
								},
							};
						});
					}
				}
				break;

			case GAME_CONSTANTS.EVENTS.skip_card_undealt.name:
				closed_deck_cards_api_ref.current?.start((index: number) => {
					if (index === 0) {
						return {
							to: async (next: any) => {
								try {
									await next({
										angle: 0,
										x: GAME_CONSTANTS.DISCARD_ANIMATION_MID_CARD_POS.x,
										y: GAME_CONSTANTS.DISCARD_ANIMATION_MID_CARD_POS.y, // slightly above center line
										zIndex: GAME_CONSTANTS.VERY_BIG_ZINDEX,
										config: {
											duration:
												GAME_CONSTANTS.EVENTS.skip_card_undealt.animation_time.undealt_card_animation_time / 2,
										},
									});

									// discard pile position
									await next({
										x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
										y: GAME_CONSTANTS.DISCARD_PILE_POS.y, // center line
										texture: textures[event_data.extra.card],
										is_front_card: true,
										config: {
											duration:
												GAME_CONSTANTS.EVENTS.skip_card_undealt.animation_time.undealt_card_animation_time / 2,
										},
									});
								} catch (e) {
									utils.capture_exception(e);
								}
							},
						};
					}
				});
		}
	}, [event_name]);

	return {
		handle_my_user_pick_card,
		handle_my_user_throw_card,
		handle_knock_gin_biggin,
		textures,
		event_name,
		gti,
		my_user_id,
		opponent_id,
		event_data,
		is_no_data,
		my_user_card_positions,
		my_user_combined_cards,
		discard_pile_cards,
		closed_deck_cards_count,
		opponent_total_cards,
		my_user_cards_api_ref,
		opponent_cards_api_ref,
		discard_pile_cards_api_ref,
		closed_deck_cards_api_ref,
		deadwood_bar_api_ref,
		handle_exit_in_game,
		lottie_modal_details,
		set_lottie_modal_details,
		handle_lottie_modal_close,
		handle_open_lottie_modal,
		back_card,
		my_user_match_score,
		opponent_match_score,
		handle_show_next_round_starting,
		show_next_round_starting,
		show_replay_screen,
		handle_show_replay_screen,
		my_profile_picture,
		set_my_user_match_score,
		handle_open_counter_modal,
		set_counter_modal_details,
		handle_counter_modal_close,
		counter_modal_details,
	};
};

export default useGame;
