import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import Text from 'src/common/@the-source/atoms/Text';
import { GAME_CONSTANTS } from '../constants';
import theme from 'src/utils/theme';

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		height: GAME_CONSTANTS.GAME_AREA_HEIGHT,
		width: GAME_CONSTANTS.GAME_AREA_WIDTH,
	},
	centered_content: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 200,
		width: 300,
	},
	countdown_shadow: {
		position: 'absolute',
		fontSize: 140,
		textShadow: '0px 4px 0 #FFFBCB',
	},
	countdown: {
		position: 'absolute',
		background: `linear-gradient(180deg, ${theme.colors.putty} 35.62%, ${theme.colors.lemon_chiffon} 54.95%, ${theme.colors.putty} 74.68%)`,
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontSize: 140,
	},
	match_starting: {
		color: theme.colors.white,
		fontSize: 20,
		fontFamily: theme.fonts.brawler_regular,
	},
};

const NextRoundStarting = () => {
	const [timer, set_timer] = useState(3);

	useEffect(() => {
		const interval = setInterval(() => {
			set_timer((prev_timer) => {
				if (prev_timer > 0) {
					return prev_timer - 1;
				} else {
					clearInterval(interval);
					return 0;
				}
			});
		}, 1_000);

		return () => clearInterval(interval);
	}, [timer]);

	return (
		<Box sx={styles.container}>
			<Box sx={styles.centered_content}>
				<Text sx={styles.match_starting}>Next Round starting in</Text>
				<Box sx={styles.centered_content}>
					<Text bold sx={styles.countdown_shadow}>
						{timer}
					</Text>
					<Text bold sx={styles.countdown}>
						{timer}
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default NextRoundStarting;
