import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { useSpring, useSprings } from '@react-spring/web';

import { ALLOWED_ACTIONS, FINISH_TYPES, GAME_CONSTANTS, PICK_CARD_FROM } from 'src/screens/Game/constants';
import Card from '../../Card';
import GameContext from 'src/screens/Game/context';
import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';
import PixiText from 'src/common/@the-source/atoms/PixiText';
import { Sprite } from '@pixi/react-animated';

const TableDeck = () => {
	const {
		handle_my_user_pick_card,
		my_user_card_positions,
		discard_pile_cards,
		closed_deck_cards_count,
		closed_deck_cards_api_ref,
		discard_pile_cards_api_ref,
		textures,
		gti,
		my_user_id,
		back_card,
	} = useContext(GameContext);

	const [closed_deck_cards_springs, closed_deck_cards_api] = useSprings(closed_deck_cards_count, (index) => ({
		from: {
			x: GAME_CONSTANTS.TABLE_DECK_POS.x,
			y: GAME_CONSTANTS.TABLE_DECK_POS.y,
			zIndex: GAME_CONSTANTS.VERY_SMALL_ZINDEX - index,
			texture: textures[PIXI_IMAGE_LINKS.back_card1.alias],
			is_front_card: false,
		},
	}));

	const is_gin_or_knock = gti.winnerInfo?.finishType === FINISH_TYPES.GIN || gti.winnerInfo?.finishType === FINISH_TYPES.KNOCK;

	const [discard_pile_cards_springs, discard_pile_cards_api] = useSprings(discard_pile_cards.length, (index) => ({
		from: {
			x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
			y: GAME_CONSTANTS.DISCARD_PILE_POS.y,
			angle: index % 2 === 0 ? -GAME_CONSTANTS.DISCARD_PILE_ROTATION : GAME_CONSTANTS.DISCARD_PILE_ROTATION,
			zIndex: GAME_CONSTANTS.VERY_SMALL_ZINDEX - index,
			texture: index === 0 && is_gin_or_knock ? textures[back_card] : textures[discard_pile_cards[index]],
			is_front_card: true,
		},
	}));

	useEffect(() => {
		discard_pile_cards_api_ref.current = discard_pile_cards_api;
	}, [discard_pile_cards_api]);

	useEffect(() => {
		closed_deck_cards_api_ref.current = closed_deck_cards_api;
	}, [closed_deck_cards_api]);

	const undealt_cards = gti.unDealtCards;

	const is_first_card_draggable =
		gti.currentTurnUserId === my_user_id && _.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.PICK_CARD);
	const handle_drag_pick_card = async (
		picked_from: PICK_CARD_FROM,
		x: number,
		y: number,
		index: number,
		drag_start_pos: { x: number; y: number },
	) => {
		const api: any = picked_from === PICK_CARD_FROM.DISCARD_PILE ? discard_pile_cards_api : closed_deck_cards_api;

		api.set((i: number) => {
			if (i === index) {
				return {
					x,
					y,
				};
			}
			return {};
		});

		const drag_distance = y - drag_start_pos.y;

		try {
			if (drag_distance < (GAME_CONSTANTS.MY_USER_MID_CARD_POS.y - GAME_CONSTANTS.DISCARD_PILE_POS.y) / 2) {
				throw new Error('Go back');
			}

			handle_my_user_pick_card(picked_from);
		} catch (e) {
			if (picked_from === PICK_CARD_FROM.DISCARD_PILE) {
				discard_pile_cards_api.start((i: number) => {
					return {
						to:
							i === 0
								? [
										{
											x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
											y: GAME_CONSTANTS.DISCARD_PILE_POS.y,
										},
									]
								: [],
						config: {
							duration: 500,
						},
					};
				});
			} else {
				closed_deck_cards_api.start((i: number) => {
					return {
						to:
							i === 0
								? [
										{
											x: GAME_CONSTANTS.TABLE_DECK_POS.x,
											y: GAME_CONSTANTS.TABLE_DECK_POS.y,
										},
									]
								: [],
						config: {
							duration: 500,
						},
					};
				});
			}
		}
	};
	const config = {
		spring: { mass: 10, tension: 500, friction: 100 },
		stage: { antialias: true, backgroundColor: 0x1099bb },
		duration: 1500,
	};

	const animationConfig = {
		pickCardFromPlayerHand: [
			{
				x: my_user_card_positions[3].x,
				y: my_user_card_positions[3].y - 10,
				alpha: 0,
			},
			{
				x: my_user_card_positions[3].x,
				y: my_user_card_positions[3].y - 10,
				alpha: 1,
			},
			{
				x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
				y: GAME_CONSTANTS.DISCARD_PILE_POS.y,
				alpha: 1,
			},
			{
				x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
				y: GAME_CONSTANTS.DISCARD_PILE_POS.y,
				alpha: 0,
			},
		],
		pickCardFromDiscardPile: [
			{
				x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
				y: GAME_CONSTANTS.DISCARD_PILE_POS.y - 50,
				alpha: 0,
			},
			{
				x: GAME_CONSTANTS.DISCARD_PILE_POS.x - 20,
				y: GAME_CONSTANTS.DISCARD_PILE_POS.y - 10,
				alpha: 1,
			},
			{
				x: my_user_card_positions[3].x,
				y: my_user_card_positions[3].y - 10,
				alpha: 1,
			},
			{
				x: my_user_card_positions[3].x,
				y: my_user_card_positions[3].y - 10,
				alpha: 0,
			},
		],
		pickCardFromDeck: [
			{
				x: GAME_CONSTANTS.TABLE_DECK_POS.x,
				y: GAME_CONSTANTS.TABLE_DECK_POS.y + 20,
			},
			{
				x: GAME_CONSTANTS.TABLE_DECK_POS.x - 10,
				y: GAME_CONSTANTS.TABLE_DECK_POS.y,
			},
		],
	};

	const animationType =
		gti.GTINumber === 3 ? 'pickCardFromDiscardPile' : gti.GTINumber === 4 ? 'pickCardFromPlayerHand' : 'pickCardFromDeck';
	const [props, api] = useSpring(() => ({
		from: animationConfig[animationType][0],
		config: config.spring,
	}));

	useEffect((): any => {
		const animate = async () => {
			await new Promise((resolve) => setTimeout(resolve, 400));
			while (true) {
				for (let i = 0; i < animationConfig[animationType].length; i++) {
					api.start({
						to: animationConfig[animationType][i],
					});
					await new Promise((resolve) => setTimeout(resolve, !i ? 800 : 1500));
				}
			}
		};

		animate();

		return () => api.stop();
	}, []);

	return (
		<>
			{/* Map through closed_deck_cards */}

			{[...Array(5)].map((__, index) => (
				<Card
					key={`extra-card-${index}`}
					card_props={{
						x: GAME_CONSTANTS.TABLE_DECK_POS.x + 2 * (4 - index),
						y: GAME_CONSTANTS.TABLE_DECK_POS.y + 2 * (4 - index),
						texture: textures[PIXI_IMAGE_LINKS.back_card1.alias],
						is_front_card: false,
					}}
				/>
			))}
			{_.map(closed_deck_cards_springs, (card_props, index) => {
				if (gti.GTINumber === 6) {
					card_props.zIndex = 149 as any;
				}
				return (
					<Card
						key={`closed-deck-${index}`}
						card_props={card_props}
						draggable={
							(index === closed_deck_cards_count - 1 || index === 0) &&
							is_first_card_draggable &&
							!_.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.PASS_TURN)
						}
						handle_play={({ x, y, drag_start_pos }) => {
							if (!_.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.PASS_TURN)) {
								handle_drag_pick_card(PICK_CARD_FROM.UN_DEALT_CARDS, x, y, index, drag_start_pos);
							}
						}}
						onclick={() => handle_my_user_pick_card(PICK_CARD_FROM.UN_DEALT_CARDS)}
					/>
				);
			})}

			{/* Map through discard_pile_cards */}
			{_.map(discard_pile_cards_springs, (card_props, index) => {
				if (gti.GTINumber === 3) {
					card_props.zIndex = 149 as any;
				}
				return (
					<Card
						key={`discard-pile-${index}`}
						card_props={card_props}
						draggable={(index === discard_pile_cards.length || index === 0) && is_first_card_draggable}
						handle_play={({ x, y, drag_start_pos }) =>
							handle_drag_pick_card(PICK_CARD_FROM.DISCARD_PILE, x, y, index, drag_start_pos)
						}
						onclick={() => handle_my_user_pick_card(PICK_CARD_FROM.DISCARD_PILE)}
					/>
				);
			})}

			{(gti.GTINumber === 3 || gti.GTINumber === 4 || gti.GTINumber === 6) && (
				<Sprite
					zIndex={200}
					height={70}
					width={70}
					// rotation={utils.degrees_to_radians(260)}
					texture={textures[PIXI_IMAGE_LINKS.pointer.alias]}
					{...props}
				/>
			)}

			<PixiText
				text={undealt_cards.toString()}
				x={GAME_CONSTANTS.TABLE_DECK_POS.x * 1.01}
				y={GAME_CONSTANTS.TABLE_DECK_POS.y * 0.99}
				alpha={1}
				zIndex={gti.GTINumber === 6 ? 200 : 99}
				styles={{ fontSize: GAME_CONSTANTS.NUMBER_ANIMATION_TEXT_SIZE, fontWeight: '200' }}
			/>
		</>
	);
};

export default TableDeck;
