import { useContext, useEffect } from 'react';
import _ from 'lodash';
import { useSprings } from '@react-spring/web';

import Card from '../../Card';
import GameContext from 'src/screens/Game/context';
import { ALLOWED_ACTIONS, PICK_CARD_FROM } from 'src/screens/Game/constants';

const MyUserCardsSection = () => {
	const {
		gti,
		my_user_id,
		handle_my_user_throw_card,
		my_user_card_positions,
		my_user_combined_cards,
		my_user_cards_api_ref,
		textures,
	} = useContext(GameContext);

	const last_picked_card = gti.players[my_user_id].lastPickedCard;
	const last_picked_card_from = gti.players[my_user_id].lastPickedCardFrom;

	const [my_user_card_springs, my_user_cards_api] = useSprings(my_user_combined_cards.length, (index) => {
		const { x, y } = my_user_card_positions[index];
		return {
			from: {
				x,
				y,
				zIndex: index,
				texture: textures[my_user_combined_cards[index]],
			},
		};
	});

	useEffect(() => {
		my_user_cards_api_ref.current = my_user_cards_api;
	}, [my_user_cards_api]);

	let matchFound = false;
	return (
		<>
			{_.map(my_user_card_springs, (card_props, index) => {
				let is_last_picked_card_from_discard_pile =
					last_picked_card === my_user_combined_cards[index] && last_picked_card_from === PICK_CARD_FROM.DISCARD_PILE;

				let is_cards_draggable =
					gti.currentTurnUserId === my_user_id &&
					_.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.DISCARD_CARD) &&
					!is_last_picked_card_from_discard_pile;

				if (gti.GTINumber === 4) {
					if (matchFound) {
						card_props.zIndex = (145 + index) as any;
					}
					if (index !== 4) {
						is_last_picked_card_from_discard_pile = true;
						is_cards_draggable = false;
					} else {
						card_props.zIndex = 145 as any;
						matchFound = true;
					}
				}
				return (
					<Card
						key={index}
						card_props={{
							...card_props,
							is_front_card: true,
							meld_color: my_user_card_positions[index].meld_color || '',
						}}
						handle_play={({ x, y, drag_start_pos }) => handle_my_user_throw_card(x, y, index, drag_start_pos)}
						draggable={is_cards_draggable}
						show_grey_overlay={is_last_picked_card_from_discard_pile}
					/>
				);
			})}
		</>
	);
};

export default MyUserCardsSection;
