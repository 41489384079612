import { useContext, useEffect } from 'react';
import { Container } from '@pixi/react-animated';
import _ from 'lodash';
import { easings, useSpring, useSprings } from '@react-spring/web';

import Card from './Card';
import utils from 'src/utils/utils';
import { GAME_CONSTANTS } from '../constants';
import { get_starting_of_cards_pos, get_my_user_card_positions } from '../helper';
import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';
import GameContext from '../context';
import cardDealSfx from '../../../assets/audio/dealcard.wav';
import flipCard from '../../../assets/audio/flipcard.mp3';
import PixiText from 'src/common/@the-source/atoms/PixiText';
import Sound from 'src/classes/Sound';

const CardDistribute = () => {
	const { gti, textures, deadwood_bar_api_ref } = useContext(GameContext);

	const my_user_id = gti.gameUserId;

	const { hand = [], meld = [] } = gti.players[my_user_id].hand;
	const undealt_cards = gti.unDealtCards;

	const player_total_cards = hand.length + _.flatten(meld).length; //total cards to be distributed to each player

	const player_first_card_pos = get_starting_of_cards_pos(player_total_cards);

	const back_card = PIXI_IMAGE_LINKS.back_card1.alias;

	const is_opponent = (index: number) => index % 2 === 0;

	const my_user_card_positions = get_my_user_card_positions(hand, meld);

	const discard_pile_first_card = gti.discardPile[0];

	const my_user_cards = [...hand, ..._.flatten(meld)];

	const [springs, api] = useSprings(GAME_CONSTANTS.TOTAL_CARDS, (index) => ({
		from: {
			angle: 0,
			x: GAME_CONSTANTS.CARD_DECK_INIT_POS.x,
			y: GAME_CONSTANTS.CARD_DECK_INIT_POS.y,
			zIndex: GAME_CONSTANTS.TOTAL_CARDS - index,
			xscale: GAME_CONSTANTS.CARD_SCALE,
			yscale: GAME_CONSTANTS.CARD_SCALE,
			texture: textures[back_card],
			is_front_card: false,
		},
	}));

	const [number_animation_spring, number_animation_api] = useSpring(() => ({
		from: {
			x: GAME_CONSTANTS.TABLE_DECK_POS.x * 1.01,
			y: GAME_CONSTANTS.TABLE_DECK_POS.y * 0.99,
			alpha: 0,
		},
		config: {
			duration: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.number_animation_duration,
		},
	}));

	const distribute_animation = async () => {
		await new Promise((resolve) => {
			api.start((index) => ({
				to: async (next) => {
					try {
						// card deck initial to target pos
						if (index > 47) {
							await next({
								x: GAME_CONSTANTS.TABLE_DECK_POS.x + 2 * (index - 47),
								y: GAME_CONSTANTS.TABLE_DECK_POS.y + 2 * (index - 47),
								config: {
									duration: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.table_deck_appear,
								},
								texture: textures[back_card],
							});
						} else {
							await next({
								x: GAME_CONSTANTS.TABLE_DECK_POS.x,
								config: {
									duration: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.table_deck_appear,
								},
								texture: textures[back_card],
							});
						}

						// wait before dealing
						await next({ delay: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.pre_deal_delay });

						// card deal animation
						if (index < GAME_CONSTANTS.CARDS_TO_DEAL_AT_START) {
							await next({
								angle: 0,
								y: is_opponent(index) ? GAME_CONSTANTS.OPP_MID_CARD_POS.y : GAME_CONSTANTS.MY_USER_MID_CARD_POS.y,
								x: player_first_card_pos + Math.floor(index / 2) * GAME_CONSTANTS.SPACE_BW_CARDS(10),
								config: {
									duration: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.card_animation_duration,
								},
								delay: index * GAME_CONSTANTS.EVENTS.dealt_card.animation_time.card_animation_duration, // Apply delay only here
								zIndex: is_opponent(index) ? GAME_CONSTANTS.TOTAL_CARDS - index : index,
								texture: is_opponent(index) ? textures[back_card] : textures[my_user_cards[Math.floor(index / 2)]], // Change texture here,
								is_front_card: true,
								onStart: () => {
									Sound.play('card_deal');
								},
							});
						}
					} catch (e) {
						utils.capture_exception(e);
					}
				},
				onRest: () => {
					if (index === GAME_CONSTANTS.CARDS_TO_DEAL_AT_START - 1) {
						resolve(null);
					}
				},
			}));
		});
	};

	const discard_pile_animation = async () => {
		await new Promise((resolve) => {
			api.start((index) => ({
				to: async (next) => {
					try {
						if (index === GAME_CONSTANTS.CARDS_TO_DEAL_AT_START) {
							await next({
								angle: 0,
								x: GAME_CONSTANTS.DISCARD_ANIMATION_MID_CARD_POS.x,
								y: GAME_CONSTANTS.DISCARD_ANIMATION_MID_CARD_POS.y, // slightly above center line
								config: {
									duration: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.card_animation_duration,
								},
								zIndex: GAME_CONSTANTS.VERY_BIG_ZINDEX,
								delay: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.discard_card_animation_delay,
								xscale: 0,
								texture: textures[back_card],
								is_front_card: false,
								onStart: () => {
									Sound.play('flip_card');
								},
							});

							// discard pile position
							await next({
								angle: -5,
								x: GAME_CONSTANTS.DISCARD_PILE_POS.x,
								y: GAME_CONSTANTS.DISCARD_PILE_POS.y, // center line
								config: {
									duration: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.discard_halfway_to_final_duration,
								},
								zIndex: 0,
								xscale: GAME_CONSTANTS.CARD_SCALE,
								texture: textures[discard_pile_first_card],
								is_front_card: true,
							});

							// start the number_animation
							number_animation_api.start({
								to: { alpha: 1 },
							});
						}
					} catch (e) {
						utils.capture_exception(e);
					}
				},
				//@todo ask whether to take the duration from constant
				onRest: () => {
					deadwood_bar_api_ref?.current.start({
						to: { transform: 'translateY(0%)' },
						config: {
							duration: 500,
							easing: easings.easeOutBack,
						},
					});
					if (index === GAME_CONSTANTS.CARDS_TO_DEAL_AT_START) {
						resolve(null);
					}
				},
			}));
		});
	};

	const meld_separate_animation = async () => {
		await new Promise(() => {
			api.start((index) => ({
				to: async (next) => {
					try {
						if (index < GAME_CONSTANTS.CARDS_TO_DEAL_AT_START && !is_opponent(index)) {
							const { x, y } = my_user_card_positions[Math.floor(index / 2)];

							await next({ delay: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.meld_seperate_delay });

							// Final animation to separate hand and meld cards
							await next({
								x,
								y,
								is_front_card: true,
								config: { duration: GAME_CONSTANTS.EVENTS.dealt_card.animation_time.card_animation_duration },
							});
						}
					} catch (e) {
						utils.capture_exception(e);
					}
				},
			}));
		});
	};
	const playCardDealSfx = () => {
		// eslint-disable-next-line no-undef
		const sfx = new Audio(cardDealSfx);
		sfx.play();
	};
	const playFlipCardSfx = () => {
		// eslint-disable-next-line no-undef
		new Audio(flipCard).play();
	};

	useEffect(() => {
		setTimeout(async () => {
			for (let index = 0; index < 21; index++) {
				await new Promise((resolve) => setTimeout(resolve, index === 20 ? 800 : 187));
				if (index === 20) {
					playFlipCardSfx();
				} else {
					playCardDealSfx();
				}
			}
		}, 1100);
		(async () => {
			await distribute_animation();
			await discard_pile_animation();
			await meld_separate_animation();
		})();
	}, []);

	return (
		<Container sortableChildren={true}>
			{_.map(springs, (spring, index) => {
				return (
					<Card
						key={index}
						card_props={{
							...spring,
						}}
					/>
				);
			})}
			<PixiText
				text={undealt_cards.toString()}
				zIndex={GAME_CONSTANTS.VERY_BIG_ZINDEX}
				styles={{
					fontSize: GAME_CONSTANTS.NUMBER_ANIMATION_TEXT_SIZE,
					fontWeight: '200',
				}}
				{...number_animation_spring}
			/>
		</Container>
	);
};

export default CardDistribute;
