import _ from 'lodash';
import { useSpring, animated } from 'react-spring';
import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import CountUp from 'react-countup';

import { ALLOWED_ACTIONS, FINISH_TYPES, GAME_CONSTANTS, GAME_STATE } from '../constants';
import SocketClass from 'src/classes/SocketClass';
import GameContext from '../context';
import ImageLinks from 'src/assets/images/ImageLinks';
import Text from '../../../common/@the-source/atoms/Text';
import theme from 'src/utils/theme';
import Sound from 'src/classes/Sound';
import TurnTimer from 'src/common/TurnTimer';
import NetworkIcon from 'src/common/NetworkIcon';
import CONSTANTS from 'src/utils/constants';
import useGameStore from 'src/store/useGameStore';
import ProfilePic, { RING_CHILD_VALUE } from 'src/common/ProfilePic';
import CommonUserSection from 'src/common/CommonUserSection';

const styles: { [key: string]: React.CSSProperties } = {
	deadwood_bar_container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		width: 180,
		margin: '0 auto',
		zIndex: 0,
		bottom: GAME_CONSTANTS.USER_SECTION_HEIGHT,
		right: '10%',
	},
	text: {
		color: theme.colors.white,
		fontSize: 12,
		position: 'absolute',
		top: '8px',
	},
	text_left: {
		left: '8%',
	},
	text_right: {
		right: '10%',
		top: '0px',
	},
	profile_container: {
		position: 'absolute',
		left: '10%',
		bottom: '25%',
		zIndex: GAME_CONSTANTS.VERY_BIG_ZINDEX,
	},
};

const MyUserSection = () => {
	const { gti, my_user_id, deadwood_bar_api_ref, event_name, my_user_match_score, opponent_id } = useContext(GameContext);

	const [old_match_score, set_old_match_score] = React.useState(my_user_match_score);

	const { skipTurnCount } = gti.players[my_user_id];
	const game_store = useGameStore.getState();
	let ring_child = {
		value: RING_CHILD_VALUE.PROFILE_PIC,
		onClick: _.noop,
	};

	if (gti.currentTurnUserId === my_user_id) {
		if (_.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.PASS_TURN)) {
			ring_child = {
				value: RING_CHILD_VALUE.PASS_BUTTON,
				onClick: () => {
					Sound.play('button_click');
					game_store.pass_turn({ gti }, opponent_id);
					SocketClass.emit(GAME_CONSTANTS.USER_ACTIONS.PASS_TURN);
				},
			};
		}

		if (_.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.GIN)) {
			ring_child = {
				value: RING_CHILD_VALUE.GIN_BUTTON,
				onClick: () => {
					Sound.play('button_click');
					SocketClass.emit(GAME_CONSTANTS.USER_ACTIONS.GIN, {
						card: gti.turnInfo?.ginCard,
					});
				},
			};
		}

		if (_.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.BIG_GIN)) {
			ring_child = {
				value: RING_CHILD_VALUE.BIG_GIN_BUTTON,
				onClick: () => {
					Sound.play('button_click');
					SocketClass.emit(GAME_CONSTANTS.USER_ACTIONS.BIG_GIN);
				},
			};
		}

		if (_.includes(gti.turnInfo?.allowedActions, ALLOWED_ACTIONS.KNOCK)) {
			ring_child = {
				value: RING_CHILD_VALUE.KNOCK_BUTTON,
				onClick: () => {
					Sound.play('button_click');
					game_store.update_game_info(GAME_CONSTANTS.EVENTS.card_thrown.name, {
						gti: {
							GTINumber: 8,
							turnInfo: {
								...gti.turnInfo,
								allowedActions: [],
							},
						},
						extra: {
							card: 'D7',
							gameUserId: my_user_id,
							hand: {
								meld: [
									['H1', 'H2', 'H3'],
									['C4', 'C5', 'C6'],
									['S11', 'S12', 'S13'],
								],
								hand: ['H5'],
								dw: 5,
							},
							finishType: FINISH_TYPES.KNOCK,
						},
					});
					setTimeout(() => {
						game_store.game_finish(GAME_CONSTANTS.EVENTS.game_result_screen.name, {
							gti: {
								GTINumber: 8,
								winnerInfo: {
									winnerId: my_user_id,
									winnerType: FINISH_TYPES.KNOCK,
									winnerScore: 25,
								},
							},
							extra: {
								players: {
									[my_user_id]: {
										hand: {
											meld: [
												['H1', 'H2', 'H3'],
												['C4', 'C5', 'C6'],
												['S11', 'S12', 'S13'],
											],
											hand: ['H5'],
											dw: 5,
											newDw: 5,
										},
									},
									[opponent_id]: {
										hand: {
											meld: [
												['D6', 'D7', 'D8'],
												['C10', 'D10', 'H10'],
											],
											hand: ['D4', 'D1', 'C1', 'H4'],
											dw: 10,
											newDw: 6,
										},
									},
								},
								knockerId: my_user_id,
								winnerId: my_user_id,
								finishType: FINISH_TYPES.KNOCK,
								layoffCards: [
									{
										card: 'H4',
										deadwoodIndex: 3,
										knockerMeldIndex: 0,
										knockerMeldCardIndex: 3,
									},
								],
							},
						});
					}, 600);
				},
			};
		}
	}

	const my_user_deadwood_score = gti.players[my_user_id].hand.dw;

	const [deadwood_bar_spring, deadwood_bar_api] = useSpring(() => ({
		from: { transform: 'translateY(100%)' },
	}));

	useEffect(() => {
		deadwood_bar_api_ref.current = deadwood_bar_api;
	}, [deadwood_bar_api]);

	const turn_info = gti.currentTurnUserId === my_user_id ? gti.turnInfo : undefined;

	useEffect(() => {
		if (old_match_score !== my_user_match_score) {
			let timeout = setTimeout(() => {
				set_old_match_score(my_user_match_score);
			}, 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [my_user_match_score]);

	return (
		<>
			<CommonUserSection
				show_grey_overlay={gti.gameState === GAME_STATE.FIRST_TURN}
				showPointerOnButton={
					gti.turnInfo &&
					((gti.turnInfo.allowedActions as ALLOWED_ACTIONS[]).includes(ALLOWED_ACTIONS.PASS_TURN) ||
						(gti.turnInfo.allowedActions as ALLOWED_ACTIONS[]).includes(ALLOWED_ACTIONS.KNOCK))
						? true
						: false
				}>
				<Box sx={styles.profile_container}>
					<ProfilePic ring_child={ring_child} profile_picture='https://d29i2jg7pajoz0.cloudfront.net/avatars/3.png' />
					{_.map(
						[
							{
								left: '0%',
								bottom: '-4%',
								transform: 'rotate(38deg)',
							},
							{
								left: '34%',
								bottom: '-14%',
							},
							{
								right: '0%',
								bottom: '-4%',
								transform: 'rotate(-38deg)',
							},
						],
						(val, key) => {
							return (
								<Box key={`skip_${key}`} sx={{ position: 'absolute', ...val }}>
									{key < skipTurnCount ? <ImageLinks.skip_red /> : <ImageLinks.skip />}
								</Box>
							);
						},
					)}
				</Box>

				<Box
					sx={{
						marginLeft: 110,
						marginTop: 20,
						backgroundImage: `url(${ImageLinks.score_bg})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						width: 170,
						height: 50,
						paddingRight: 20,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}>
					<Box sx={{ width: 110, textAlign: 'center' }}>
						<Text
							pridi
							medium
							sx={{
								fontSize: 18,
								background: `linear-gradient(to right, ${theme.colors.putty} 18%, ${theme.colors.lemon_chiffon} 52.7%, ${theme.colors.putty} 86.83%)`,
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent',
							}}>
							<Text pridi medium component={'span'} sx={{ fontSize: 36 }}>
								<CountUp start={old_match_score} end={my_user_match_score} duration={1} />/
							</Text>
							100
						</Text>
					</Box>
				</Box>

				{!_.isEmpty(turn_info) && (
					<Box sx={[{ position: 'absolute', width: CONSTANTS.WIDTH * 0.9 - 110, top: 0, right: 0 }]}>
						<TurnTimer turn_info={turn_info} />
					</Box>
				)}
				<NetworkIcon container_styles={{ position: 'absolute', right: '10%', bottom: '20%' }} is_network_active={true} />
			</CommonUserSection>
			<animated.div
				style={{
					...(event_name === GAME_CONSTANTS.EVENTS.dealt_card.name ? deadwood_bar_spring : {}),
					...styles.deadwood_bar_container,
				}}>
				<ImageLinks.deadwood_score_bar width={'100%'} height={'100%'} />
				<Box sx={[styles.text, styles.text_left]}>
					<Text pridi sx={{ fontSize: 17 }}>
						Deadwood:
					</Text>
				</Box>
				<Box sx={[styles.text, styles.text_right]}>
					<Text
						pridi
						medium
						sx={{
							fontSize: 16,
							background: `linear-gradient(90deg, ${theme.colors.alto} 0%, ${theme.colors.white} 49.5%, ${theme.colors.alto} 100%)`,
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
						}}>
						<Text pridi medium component={'span'} sx={{ fontSize: 26 }}>
							{my_user_deadwood_score}/
						</Text>
						10
					</Text>
				</Box>
			</animated.div>
		</>
	);
};

export default MyUserSection;
