import { Box } from '@mui/material';
import { useState, useEffect, useContext } from 'react';

import theme from 'src/utils/theme';
import ImageLinks from 'src/assets/images/ImageLinks';
import Text from 'src/common/@the-source/atoms/Text';
import IconButtonPrimary from 'src/common/@the-source/atoms/IconButtonPrimary';
import { GAME_CONSTANTS } from '../../constants';
import UserSection from './components/UserSection';
import GameContext from '../../context';
import Button from 'src/common/@the-source/atoms/Button';
import CONSTANTS from 'src/utils/constants';
import { quit_webview } from 'src/utils/helper';
import useApp from 'src/store/useApp';
import { useShallow } from 'zustand/react/shallow';
import api_requests from 'src/utils/api_requests';
import SocketClass from 'src/classes/SocketClass';

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.black,
		height: '100%',
		width: '100%',
		backgroundImage: `url(${ImageLinks.game_bg})`,
		backgrouncSize: 'cover',
	},
	opponent_section: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: GAME_CONSTANTS.USER_SECTION_HEIGHT,
	},
	matchmaking: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		height: GAME_CONSTANTS.GAME_AREA_HEIGHT,
		width: GAME_CONSTANTS.GAME_AREA_WIDTH,
	},
	centered_content: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 200,
		width: 300,
	},
	countdown_shadow: {
		position: 'absolute',
		fontSize: 140,
		textShadow: '0px 4px 0 #FFFBCB',
	},
	countdown: {
		position: 'absolute',
		background: `linear-gradient(180deg, ${theme.colors.putty} 35.62%, ${theme.colors.lemon_chiffon} 54.95%, ${theme.colors.putty} 74.68%)`,
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontSize: 140,
	},
	match_starting: {
		color: theme.colors.white,
		fontSize: 20,
		fontFamily: theme.fonts.brawler_regular,
	},
	button_container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 50,
	},
	lottie_style: {
		width: '70%',
	},
};

const Replay = () => {
	const { my_profile_picture } = useContext(GameContext);

	const [timer, set_timer] = useState(0);

	const { show_loader, hide_loader } = useApp(
		useShallow((state) => ({
			show_loader: state.show_loader,
			hide_loader: state.hide_loader,
		})),
	);

	const getActivePoolInfo = async () => {
		const data = await api_requests.game.get_active_pool_info();
		const remainingTime = Math.max(0, Math.floor((new Date(Number(data.data.endTime)).getTime() - new Date().getTime()) / 1000));
		if (remainingTime > 0) {
			set_timer(remainingTime);
		}
	};
	useEffect(() => {
		(async () => {
			await getActivePoolInfo();
		})();
	}, []);

	useEffect(() => {
		const interval = setInterval(async () => {
			if (timer <= 0) {
				await getActivePoolInfo();
			} else {
				set_timer((prev_timer) => {
					if (prev_timer > 0) {
						return prev_timer - 1;
					} else {
						return 0;
					}
				});
			}
		}, 1_000);

		return () => clearInterval(interval);
	}, [timer]);

	const handle_play = async () => {
		try {
			show_loader();

			const res = await api_requests.game.get_lobbies();

			await api_requests.game.play_registration({
				lobbyId: res.data.lobby._id,
			});

			SocketClass.connect_socket(CONSTANTS.SOCKET_URL);
		} catch (err) {
		} finally {
			hide_loader();
		}
	};

	const handle_cancel = () => {
		if (CONSTANTS.IS_FREEWIN()) {
			quit_webview();
		} else {
		}
	};

	return (
		<Box sx={styles.container}>
			<UserSection
				is_opponent={true}
				show_profile={false}
				left_button={
					<IconButtonPrimary
						onClick={handle_cancel}
						icon={<ImageLinks.exit_matchmaking_icon width={'100%'} height={'100%'} />}
					/>
				}
			/>

			<Box sx={styles.matchmaking}>
				<Box sx={styles.centered_content}>
					<Text sx={styles.match_starting}>Match starting in</Text>
					<Box sx={styles.centered_content}>
						<Text bold sx={styles.countdown_shadow}>
							{timer}
						</Text>
						<Text bold sx={styles.countdown}>
							{timer}
						</Text>
					</Box>
				</Box>
				<Box sx={styles.button_container}>
					<Button
						Bg_image={ImageLinks.yellow_button2}
						title='Play'
						container_styles={{ width: 200, height: 100 }}
						text_styles={{ fontSize: 30 }}
						onClick={handle_play}
					/>
					<Button container_styles={{ width: 80, height: 80, marginLeft: 20 }} onClick={handle_cancel}>
						<img src={ImageLinks.cross_circle_button} width={'100%'} height={'100%'} />
					</Button>
				</Box>
			</Box>

			<UserSection profile_picture={my_profile_picture} />
		</Box>
	);
};

export default Replay;
