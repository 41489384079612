import { useEffect } from 'react';
import TableDeck from './components/TableDeck';
import OpponentCardsSection from './components/OpponentCardsSection';
import MyUserCardsSection from './components/MyUserCardsSection';

import { useSpring } from '@react-spring/web';
import { Sprite } from '@pixi/react-animated';
import { GAME_CONSTANTS } from '../../constants';
import { Texture } from '@pixi/core';

const Overlay = ({ visible }: { visible: boolean }) => {
	const [props, api] = useSpring(() => ({
		from: {
			alpha: visible ? 0 : 0.5,
		},
		config: { duration: 1000 },
	}));

	useEffect(() => {
		if (visible) {
			api.start({ to: { alpha: 0.5 } });
		} else {
			api.start({ to: { alpha: 0 } });
		}
		return () => {
			api.stop();
		};
	}, [visible]);

	return (
		<Sprite
			zIndex={100}
			height={GAME_CONSTANTS.GAME_AREA_HEIGHT}
			width={GAME_CONSTANTS.GAME_AREA_WIDTH}
			tint={0x000000}
			texture={Texture.WHITE}
			{...props}
		/>
	);
};

const Gameplay = ({ showGreyOverLay }: { showGreyOverLay: boolean }) => {
	return (
		<>
			<OpponentCardsSection />
			<TableDeck />
			<MyUserCardsSection />
			<Overlay visible={showGreyOverLay} />
		</>
	);
};

export default Gameplay;
