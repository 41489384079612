import { Container, Sprite, Graphics } from '@pixi/react-animated';
import { Texture } from '@pixi/core';
import * as PIXI from 'pixi.js';
import { useRef } from 'react';
import { Interpolation, SpringValue, to } from '@react-spring/web';
import { EventMode } from '@pixi/events';
import _ from 'lodash';

import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';
import { GAME_CONSTANTS } from '../constants';
import theme from 'src/utils/theme';
import useGameStore from 'src/store/useGameStore';
import { useShallow } from 'zustand/react/shallow';

interface Props {
	card_props: {
		x: SpringValue | number;
		y: SpringValue | number;
		texture: SpringValue | Texture;
		is_front_card: SpringValue | boolean;

		xscale?: SpringValue | number;
		yscale?: SpringValue | number;
		angle?: SpringValue | number;
		zIndex?: SpringValue | number;
		meld_color?: string;
	};

	draggable?: boolean;
	handle_play?: (param: { x: number; y: number; drag_start_pos: { x: number; y: number } }) => void;
	handle_come_back?: (param: { x: number; y: number }) => void;
	event_mode?: EventMode;
	onclick?: () => void;
	show_grey_overlay?: boolean;
}

const Card = ({ card_props, draggable = false, handle_play, event_mode = 'static', onclick = _.noop, show_grey_overlay }: Props) => {
	const drag_start_pos = useRef({ x: 0, y: 0 });

	const { textures } = useGameStore(
		useShallow((state) => ({
			textures: state.textures,
		})),
	);

	const is_dragging = useRef(false);

	const on_drag_start = (event: any) => {
		if (!draggable) {
			return;
		}

		const sprite = event.currentTarget;
		sprite.alpha = 0.9;
		sprite.dragging = true;
		sprite.hitArea = new PIXI.Circle(0, 0, 13250);

		drag_start_pos.current = {
			x: sprite.position.x,
			y: sprite.position.y,
		};
	};

	const on_drag_end = (event: any) => {
		if (!draggable) {
			return;
		}
		const sprite = event.currentTarget;
		sprite.alpha = 1;
		sprite.dragging = false;
		sprite.hitArea = null;

		const { x, y } = sprite.position;

		handle_play?.({ x, y, drag_start_pos: drag_start_pos.current });
	};

	const on_drag_move = (event: any) => {
		if (!draggable) {
			return;
		}
		const sprite = event.currentTarget;
		if (sprite.dragging && ((event.movement.x !== 0 && event.movement.y !== 0) || is_dragging.current)) {
			is_dragging.current = true;
			sprite.zIndex = GAME_CONSTANTS.VERY_BIG_ZINDEX;
			sprite.parent.toLocal(event.global, null, sprite.position);
		}
	};

	const handle_click = () => {
		if (is_dragging.current) {
			is_dragging.current = false;
			return;
		}

		onclick?.();
	};

	const { yscale, xscale, texture, is_front_card, meld_color } = card_props;

	let xscale_val: number | Interpolation = GAME_CONSTANTS.CARD_SCALE;
	if (xscale) {
		if (typeof xscale === 'object') {
			xscale_val = xscale.to((value) => value);
		} else {
			xscale_val = xscale;
		}
	}

	let yscale_val: number | Interpolation = GAME_CONSTANTS.CARD_SCALE;
	if (yscale) {
		if (typeof yscale === 'object') {
			yscale_val = yscale.to((value) => value);
		} else {
			yscale_val = yscale;
		}
	}

	let back_alpha: number | Interpolation = 0;
	if (typeof is_front_card === 'object') {
		back_alpha = is_front_card.to((value) => (value ? 1 : 0));
	}

	if (typeof is_front_card === 'boolean' && is_front_card) {
		back_alpha = 1;
	}

	return (
		<Container
			sortableChildren={true}
			eventMode={event_mode}
			anchor={0.5}
			cursor={draggable ? 'pointer' : 'default'}
			scale={to([xscale_val, yscale_val], (x, y) => [x, y])}
			pointertap={handle_click}
			pointerup={on_drag_end}
			pointerupoutside={on_drag_end}
			pointermove={on_drag_move}
			pointerdown={on_drag_start}
			{...card_props}>
			<Sprite
				x={0}
				y={0}
				anchor={0.5}
				texture={textures[PIXI_IMAGE_LINKS.card_background.alias]}
				alpha={back_alpha}
				scale={1.2}
			/>
			<Sprite x={0} y={0} anchor={0.5} texture={texture as Texture} />
			{!_.isEmpty(meld_color) && (
				<Graphics
					draw={(g) => {
						g.clear();
						g.beginFill(meld_color, 0.5); //color with 50% opacity
						g.moveTo(GAME_CONSTANTS.MELD_RECTANGLE.x, GAME_CONSTANTS.MELD_RECTANGLE.y);
						g.lineTo(
							GAME_CONSTANTS.MELD_RECTANGLE.x + GAME_CONSTANTS.MELD_RECTANGLE.width,
							GAME_CONSTANTS.MELD_RECTANGLE.y,
						);
						g.arcTo(
							GAME_CONSTANTS.MELD_RECTANGLE.x + GAME_CONSTANTS.MELD_RECTANGLE.width,
							GAME_CONSTANTS.MELD_RECTANGLE.y + GAME_CONSTANTS.MELD_RECTANGLE.height,
							GAME_CONSTANTS.MELD_RECTANGLE.x +
								GAME_CONSTANTS.MELD_RECTANGLE.width -
								GAME_CONSTANTS.MELD_RECTANGLE.radius,
							GAME_CONSTANTS.MELD_RECTANGLE.y + GAME_CONSTANTS.MELD_RECTANGLE.height,
							GAME_CONSTANTS.MELD_RECTANGLE.radius,
						);
						g.lineTo(
							GAME_CONSTANTS.MELD_RECTANGLE.x + GAME_CONSTANTS.MELD_RECTANGLE.radius,
							GAME_CONSTANTS.MELD_RECTANGLE.y + GAME_CONSTANTS.MELD_RECTANGLE.height,
						);
						g.arcTo(
							GAME_CONSTANTS.MELD_RECTANGLE.x,
							GAME_CONSTANTS.MELD_RECTANGLE.y + GAME_CONSTANTS.MELD_RECTANGLE.height,
							GAME_CONSTANTS.MELD_RECTANGLE.x,
							GAME_CONSTANTS.MELD_RECTANGLE.y,
							GAME_CONSTANTS.MELD_RECTANGLE.radius,
						);
						g.lineTo(GAME_CONSTANTS.MELD_RECTANGLE.x, GAME_CONSTANTS.MELD_RECTANGLE.y);
						g.endFill();
					}}
				/>
			)}
			{show_grey_overlay && (
				<Graphics
					draw={(g) => {
						g.clear();

						// Fill the entire card area with grey
						g.beginFill(theme.colors.cod_gray, 0.3); // grey color with 50% opacity

						// Start from the top-left corner
						g.moveTo(GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x, GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y);
						g.arcTo(
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.radius,
						);
						g.arcTo(
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.radius,
						);
						g.arcTo(
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.radius,
						);
						g.arcTo(
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							-GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.x,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.y,
							GAME_CONSTANTS.NON_DRAGGABLE_CARD_OVERLAY.radius,
						);

						g.endFill();
					}}
				/>
			)}
		</Container>
	);
};

export default Card;
