import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import * as Sentry from '@sentry/react';

import useApp from 'src/store/useApp';
import CONSTANTS from './constants';
import { refresh_expire_token } from './helper';

interface RequestParams extends AxiosRequestConfig {
	cancel_token?: { cancel: Function };
	should_refresh_token?: boolean;
}

const utils = {
	request: (props: RequestParams) => {
		const {
			should_refresh_token = true,
			// show_error = true,
			// custom_error_title,
			// custom_error_message = '',
			// dont_show_error_special_error_codes = [],
			...config
		} = props || {};

		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.request({
					timeout: 15_000,
					...config,
				});
				return resolve({ ...(res.data || {}), status: res.status });
			} catch (error: any) {
				const status_code = _.get(error, 'response.status');

				if (status_code === 401 && should_refresh_token) {
					const { auth_token, refresh_token } = useApp.getState();

					//@todo refresh token
					if (!_.isEmpty(auth_token) && !_.isEmpty(refresh_token)) {
						try {
							await refresh_expire_token();
							resolve(
								utils.request({
									...props,
									should_refresh_token: false,
								}),
							);
						} catch (err) {
							reject(err);
						}

						return;
					}
				}

				// @todo add snackbar on api errors

				return reject(error);
			}
		});
	},

	set_custom_common_header: (headers: any) => {
		axios.defaults.headers.common = { ...axios.defaults.headers.common, ...headers };
	},

	set_request_auth_header: (auth_token: string) => {
		utils.set_custom_common_header({
			Authorization: auth_token,
		});
	},
	delay: (ms: number) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(null);
			}, ms);
		});
	},

	capture_exception: (err: any, hint: any = null) => {
		const env = useApp.getState().env;
		if (env === CONSTANTS.ALL_ENV.staging) {
			Sentry.captureException(err, hint);
			return;
		}

		console.log('Error ---->', err, hint);
	},
};

export default utils;
