import { GAME_STATE } from 'src/screens/Game/constants';
import { gti_type } from 'src/store/useGameStore';
import { Card_type } from 'src/utils/types';

export const ftueGTI: gti_type = {
	matchId: 'FTUE-V1',
	roundNo: 1,
	gameState: GAME_STATE.DEALING_CARDS,
	startTime: new Date().getTime(),
	GTINumber: 1,
	gameUserId: '14526388',
	players: {
		'14526388': {
			gameUserId: '14526388',
			matchScore: 0,
			username: 'Turtle',
			profilePicture: 'https://d29i2jg7pajoz0.cloudfront.net/avatars/1.jpg',
			activeInActiveStatus: 0,
			numberOfCards: 10,
			extraTurnTimeLeft: 20,
			skipTurnCount: 0,
			socketId: '',
			lastPickedCard: '' as Card_type,
			lastPickedCardFrom: '',
			lobbyId: 'test',
			hand: {
				meld: [['C4', 'C5', 'C6']],
				hand: ['H1', 'H2', 'H5', 'S11', 'S12', 'D7', 'C13'],
				dw: 45,
			},
		},
		'14526389': {
			gameUserId: '14526389',
			username: 'Ninja',
			matchScore: 0,
			hand: {
				meld: [['D6', 'D7', 'D8']],
				hand: ['D4', 'D10', 'C10', 'D1', 'H4', 'C1', 'S13'],
				dw: 40,
			},
			profilePicture: 'https://d29i2jg7pajoz0.cloudfront.net/avatars/1.jpg',
			activeInActiveStatus: 0,
			numberOfCards: 10,
			extraTurnTimeLeft: 20,
			skipTurnCount: 0,
			socketId: '',
			lastPickedCard: '' as Card_type,
			lastPickedCardFrom: '',
			lobbyId: 'test',
		},
	},
	unDealtCards: 31,
	// unDealtCards: ['C11', 'H3'],
	currentTurnUserId: '14526388',
	discardPile: ['H10'],
	gameConfig: {
		roundProgressionBonus: 0,
		dealCardDelay: 6.75,
		turnTime: 120,
		extraTurnTime: 60,
		gameStartDelay: 10,
		resultScreenTime: 10,
		knockBonus: 10,
		underCutBonus: 20,
		ginBonus: 25,
		bigGinBonus: 30,
		maxSkipTurnAllowed: 3,
	},
};
