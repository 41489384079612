import '@pixi/events';
import * as Sentry from '@sentry/react';
import { RouterProvider } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import CONSTANTS from './utils/constants';
import theme from './utils/theme';
import { router } from './utils/navigation';
import GenericModal from './common/@the-source/molecules/GenericModal';
import useApp from './store/useApp';
import Loader from './common/Loader';

if (!CONSTANTS.IS_DEV_MODE) {
	Sentry.init({
		dsn: 'https://fb6d944666c620391d1b0abb720e9716@o4507412151205888.ingest.us.sentry.io/4507418423853056',
		environment: CONSTANTS.CURRENT_ENV,
		debug: CONSTANTS.CURRENT_ENV !== CONSTANTS.ALL_ENV.production,
		...(CONSTANTS.CURRENT_ENV === CONSTANTS.ALL_ENV.production && { release: APP_VERSION }),
		// Performance Monitoring
		tracesSampleRate: 0.5,
	});
}

const PrimaryComponent = () => {
	const { initialize_app } = useApp(
		useShallow((state) => ({
			initialize_app: state.initialize_app,
		})),
	);

	useEffect(() => {
		initialize_app();
	}, []);

	return (
		<>
			<Loader />
			<GenericModal />
			<RouterProvider router={router} />
		</>
	);
};

const App = () => {
	return (
		// @todo error screen
		<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<PrimaryComponent />
				</ThemeProvider>
			</StyledEngineProvider>
		</Sentry.ErrorBoundary>
	);
};

export default App;
