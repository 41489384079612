import { Box } from '@mui/material';

import Text from 'src/common/@the-source/atoms/Text';
import theme from 'src/utils/theme';

interface Props {
	text: string;
	bonus: number;
}

const BonusModalBody = ({ text, bonus }: Props) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', width: '90%' }}>
			<Box sx={{ flex: 1 }}>
				<Text
					pridi
					medium
					style={{
						fontSize: 22,
						marginLeft: 10,
						background: `linear-gradient(90deg, ${theme.colors.equator} 0%, ${theme.colors.peach} 49.5%, ${theme.colors.equator} 100%)`,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
					}}>
					{text}
				</Text>
			</Box>
			<Text pridi medium style={{ fontSize: 36, color: theme.colors.white, marginLeft: 10 }}>
				+{bonus}
			</Text>
		</Box>
	);
};

export default BonusModalBody;
