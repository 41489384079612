
			// Import the functions you need from the SDKs you need
			import { initializeApp } from 'https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js';
			import { getAnalytics } from 'https://www.gstatic.com/firebasejs/10.13.0/firebase-analytics.js';
			// TODO: Add SDKs for Firebase products that you want to use
			// https://firebase.google.com/docs/web/setup#available-libraries

			// Your web app's Firebase configuration
			// For Firebase JS SDK v7.20.0 and later, measurementId is optional
			const firebaseConfig = {
				apiKey: 'AIzaSyCX5qp3zjwRqHwZT0mVJIrqar_HMBok4RY',
				authDomain: 'toast-dbe7e.firebaseapp.com',
				projectId: 'toast-dbe7e',
				storageBucket: 'toast-dbe7e.appspot.com',
				messagingSenderId: '625481715227',
				appId: '1:625481715227:web:a7952b255804503b28f2be',
				measurementId: 'G-PYFPG8Z04W',
			};

			// Initialize Firebase
			const app = initializeApp(firebaseConfig);
			const analytics = getAnalytics(app);
		