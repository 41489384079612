import { useSpring, animated } from '@react-spring/web';
import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { turn_info_type } from 'src/store/useGameStore';
import theme from 'src/utils/theme';
import utils from 'src/utils/utils';
import Sound from 'src/classes/Sound';

const styles = {
	progress_container: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		borderTop: `1px solid ${theme.colors.equator}`,
		borderBottom: `1px solid ${theme.colors.equator}`,
		height: 10,
	},
};

interface Props {
	turn_info: turn_info_type;
}

const TurnTimer = ({ turn_info }: Props) => {
	const { turnTime, remainingTurnTime, isExtraTurnTime } = turn_info;

	const percentage = ((turnTime - remainingTurnTime) / turnTime) * 100;
	const reversed_percentage = 100 - percentage; // reverse the percentage for the animation

	const gradient_delay = isExtraTurnTime ? 0 : remainingTurnTime <= turnTime * 0.3 ? 0 : remainingTurnTime - turnTime * 0.3;

	const intial_values = useMemo(() => {
		return {
			background: `linear-gradient(180deg, ${theme.colors.reef} 0%, ${theme.colors.de_york} 71%)`,
			boxShadow: `0px 0px 7px 1px ${theme.colors.de_york}`,
			width: `${reversed_percentage}%`,
		};
	}, [isExtraTurnTime]);

	const [spring, api] = useSpring(() => ({
		from: intial_values,
	}));

	useEffect(() => {
		api.set(intial_values);
		api.start({
			to: async (next) => {
				try {
					next({
						background: `linear-gradient(180deg, ${theme.colors.matrix} 0%, ${theme.colors.lotus} 71%)`,
						boxShadow: `0px 0px 7px 1px ${theme.colors.lotus}`,
						delay: gradient_delay * 1_000,
						config: {
							duration: 0,
						},
					});

					await next({
						width: '0%',
					});
				} catch (e) {
					utils.capture_exception(e, 'turn-timer-animation');
				}
			},
			config: {
				duration: remainingTurnTime * 1_000,
			},
		});
	}, [intial_values]);

	useEffect(() => {
		const timeout = setTimeout(
			() => {
				Sound.play('timer_end');
			},
			(remainingTurnTime - turnTime * 0.3) * 1_000,
		);
		return () => {
			clearTimeout(timeout);
			Sound.stop('timer_end');
		};
	}, [intial_values]);

	return (
		<Box sx={styles.progress_container}>
			<animated.div
				style={{
					height: 5,
					borderTopRightRadius: 45,
					borderBottomRightRadius: 45,
					...spring,
				}}
			/>
		</Box>
	);
};

export default TurnTimer;
