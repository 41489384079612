import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Stage } from '@pixi/react';
import _ from 'lodash';
import { Container } from '@pixi/react-animated';
import { useShallow } from 'zustand/react/shallow';

import GameContext from './context';
import useGame from './useGame';
import Watermark from './components/Watermark';
import theme from 'src/utils/theme';
import OpponentSection from './components/OpponentSection';
import MyUserSection from './components/MyUserSection';
import CardDistribute from './components/CardDistribute';
import { ALLOWED_ACTIONS, GAME_CONSTANTS } from './constants';
import Gameplay from './components/Gameplay/Gameplay';
import GameFinish from './components/GameFinish/GameFinish';
import LineSeperator from './components/LineSeperator';
import useGameStore from 'src/store/useGameStore';
import { RouteNames, router } from 'src/utils/navigation';
import useReconnectSocketBackground from 'src/hooks/useReconnectSocketBackground';
import NextRoundStarting from './components/NextRoundStarting';
import Replay from './components/Replay/Replay';
import CounterModal from './components/CounterModal';

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.cod_gray,
		height: '100%',
		position: 'relative',
	},
};

const GameComp = () => {
	const value = useGame();

	const {
		event_name,
		textures,
		is_no_data,
		gti,
		my_user_id,
		show_next_round_starting,
		show_replay_screen,
		handle_counter_modal_close,
		counter_modal_details,
	} = value;

	useReconnectSocketBackground();

	if (show_replay_screen) {
		return <Replay />;
	}

	if (_.isEmpty(textures) || is_no_data) {
		return null;
	}
	let showGreyOverLay = false;

	// let toolTipText = gti.GTINumber ? '' : null;
	// let toolTipPosition = {};

	if (
		gti.turnInfo &&
		(gti.turnInfo.allowedActions as ALLOWED_ACTIONS[]).includes(ALLOWED_ACTIONS.PASS_TURN) &&
		gti.currentTurnUserId === my_user_id
	) {
		showGreyOverLay = true;
		// toolTipText = "It's your turn, you can pass";
		// toolTipPosition = {
		// 	bottom: GAME_CONSTANTS.GAME_AREA_HEIGHT * 0.44,
		// 	left: GAME_CONSTANTS.X_CENTER - 115,
		// 	width: 230,
		// };
	}
	if (gti.GTINumber === 3) {
		showGreyOverLay = true;
		// toolTipText = 'You can pick a card from the discard pile';
		// toolTipPosition = {
		// 	top: GAME_CONSTANTS.GAME_AREA_HEIGHT * 0.41,
		// 	width: 200,
		// 	height: 60,
		// 	left: GAME_CONSTANTS.X_CENTER - 100,
		// };
	}
	if (gti.GTINumber === 4) {
		showGreyOverLay = true;
		// toolTipText = 'After picking a card, you can drop any card from your hand';
		// toolTipPosition = {
		// 	top: GAME_CONSTANTS.GAME_AREA_HEIGHT * 0.41,
		// 	width: 240,
		// 	height: 60,
		// 	left: GAME_CONSTANTS.X_CENTER - 120,
		// };
	}
	if (gti.GTINumber === 6) {
		showGreyOverLay = true;
		// toolTipText = 'You can pick a card from the deck';
		// toolTipPosition = {
		// 	top: GAME_CONSTANTS.GAME_AREA_HEIGHT * 0.44,
		// 	width: 260,
		// 	left: GAME_CONSTANTS.X_CENTER - 130,
		// };
	}
	// if (gti.GTINumber === 7) {
	// 	toolTipText = 'You can knock when your deadwood is below 10 or by dropping a card that can bring your deadwood below 10';
	// 	toolTipPosition = {
	// 		bottom: GAME_CONSTANTS.GAME_AREA_HEIGHT * 0.38,
	// 		left: GAME_CONSTANTS.X_CENTER - 139,
	// 		width: 260,
	// 		height: 90,
	// 	};
	// }
	// toolTipText = '';

	const show_comp = () => {
		switch (event_name) {
			case GAME_CONSTANTS.EVENTS.dealt_card.name:
				return (
					<>
						<LineSeperator />
						<Watermark />
						<CardDistribute />
					</>
				);

			case GAME_CONSTANTS.EVENTS.game_result_screen.name:
				return <GameFinish />;
			default:
				return (
					<>
						<LineSeperator />
						<Watermark />
						<Gameplay showGreyOverLay={showGreyOverLay} />
					</>
				);
		}
	};

	return (
		<GameContext.Provider value={value}>
			<Box sx={styles.container}>
				<OpponentSection />

				{/* {toolTipText && gti.GTINumber && <GameToolTip text={toolTipText} style={toolTipPosition} fontSize='18px' />} */}
				{show_next_round_starting ? (
					<NextRoundStarting />
				) : (
					<>
						<Stage
							width={GAME_CONSTANTS.GAME_AREA_WIDTH}
							height={GAME_CONSTANTS.GAME_AREA_HEIGHT}
							options={{ antialias: true, backgroundColor: 0x1c1c1c }}>
							<GameContext.Provider value={value} key={gti.GTINumber}>
								<Container sortableChildren={true}>{show_comp()}</Container>
							</GameContext.Provider>
						</Stage>
						{/* removed only for FTUE */}
						{/* <LottieModal
							open={lottie_modal_details.is_open}
							on_close={lottie_modal_details.on_close}
							handle_lottie_modal_close={handle_lottie_modal_close}
							lottie_animation={lottie_modal_details.lottie_animation}
							text_to_show={lottie_modal_details.text_to_show}
						/> */}
						<CounterModal
							open={counter_modal_details.is_open}
							on_close={counter_modal_details.on_close}
							handle_counter_modal_close={handle_counter_modal_close}
						/>
					</>
				)}

				<MyUserSection />
			</Box>
		</GameContext.Provider>
	);
};

const Game = () => {
	const { gti } = useGameStore(
		useShallow((state) => ({
			gti: state.gti,
		})),
	);

	useEffect(() => {
		if (_.isEmpty(gti)) {
			router.navigate(RouteNames.default);
		}
	}, []);

	if (_.isEmpty(gti)) {
		return null;
	}

	return <GameComp />;
};

export default Game;
