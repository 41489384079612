import _ from 'lodash';

const ImageLinks = {
	buttons: require('./game_screen/buttons.png').default,
	ring: require('./ring.png').default,
	user_section_bg: require('./user_section_bg.png').default,
	circle_button_outer: require('./game_screen/circle_button_outer.png').default,
	game_bg: require('./game_screen/game_bg.png').default,
	score_bg: require('./game_screen/score_bg.png').default,
	cross_circle_button: require('./cross_circle_button.png').default,

	//svgs
	dashed_line: require('./game_screen/dashed_line.svg?react').default,
	button_ring: require('./icons/button_ring.svg?react').default,
	button_ring_grey: require('./icons/button_ring_grey.svg?react').default,
	game_button: require('./icons/game_button.svg?react').default,
	game_button_grey: require('./icons/game_button_grey.svg?react').default,
	profile_placeholder: require('./game_screen/profile_placeholder.svg?react').default,
	network: require('./game_screen/network.svg?react').default,
	exit_matchmaking_icon: require('./game_screen/exit_matchmaking_icon.svg?react').default,
	setting_icon: require('./game_screen/setting_icon.svg?react').default,
	cross: require('./icons/cross.svg?react').default,
	yellow_button: require('./yellow_button.svg?react').default,
	yellow_button2: require('./yellow_button2.svg?react').default,
	deadwood_score_bar: require('./game_screen/deadwood_score_bar.svg?react').default,
	pointer: require('./pointer.png').default,

	us_app_logo: require('./us_app_logo.png').default,
	us_app_background: require('./us_app_background.png').default,
	play_button_bg: require('./play_button_bg.png').default,
	skip: require('./game_screen/skip.svg?react').default,
	skip_red: require('./game_screen/skip_red.svg?react').default,
};

export const PIXI_IMAGE_LINKS = {
	pointer: { alias: 'pointer', src: require('./pointer.png').default },
	line_separator: { alias: 'line_separator', src: require('./game_screen/seperator_line.png').default },
	watermark: { alias: 'watermark', src: require('./game_screen/watermark.png').default },
	score_box: { alias: 'score_box', src: require('./game_screen/score_box.png').default },
	card_background: { alias: 'card_background', src: require('./card_deck/card_background.webp').default },
	back_card1: { alias: 'back_card1', src: require('./card_deck/back_card.webp').default }, // added 1 due to react spring error
	glow_box: { alias: 'glow_box', src: require('./game_screen/glow_box.png').default },
	C1: { alias: 'C1', src: require('./card_deck/clubs/1.webp').default },
	C2: { alias: 'C2', src: require('./card_deck/clubs/2.webp').default },
	C3: { alias: 'C3', src: require('./card_deck/clubs/3.webp').default },
	C4: { alias: 'C4', src: require('./card_deck/clubs/4.webp').default },
	C5: { alias: 'C5', src: require('./card_deck/clubs/5.webp').default },
	C6: { alias: 'C6', src: require('./card_deck/clubs/6.webp').default },
	C7: { alias: 'C7', src: require('./card_deck/clubs/7.webp').default },
	C8: { alias: 'C8', src: require('./card_deck/clubs/8.webp').default },
	C9: { alias: 'C9', src: require('./card_deck/clubs/9.webp').default },
	C10: { alias: 'C10', src: require('./card_deck/clubs/10.webp').default },
	C11: { alias: 'C11', src: require('./card_deck/clubs/J.webp').default },
	C12: { alias: 'C12', src: require('./card_deck/clubs/Q.webp').default },
	C13: { alias: 'C13', src: require('./card_deck/clubs/K.webp').default },
	S1: { alias: 'S1', src: require('./card_deck/spades/1.webp').default },
	S2: { alias: 'S2', src: require('./card_deck/spades/2.webp').default },
	S3: { alias: 'S3', src: require('./card_deck/spades/3.webp').default },
	S4: { alias: 'S4', src: require('./card_deck/spades/4.webp').default },
	S5: { alias: 'S5', src: require('./card_deck/spades/5.webp').default },
	S6: { alias: 'S6', src: require('./card_deck/spades/6.webp').default },
	S7: { alias: 'S7', src: require('./card_deck/spades/7.webp').default },
	S8: { alias: 'S8', src: require('./card_deck/spades/8.webp').default },
	S9: { alias: 'S9', src: require('./card_deck/spades/9.webp').default },
	S10: { alias: 'S10', src: require('./card_deck/spades/10.webp').default },
	S11: { alias: 'S11', src: require('./card_deck/spades/J.webp').default },
	S12: { alias: 'S12', src: require('./card_deck/spades/Q.webp').default },
	S13: { alias: 'S13', src: require('./card_deck/spades/K.webp').default },
	D1: { alias: 'D1', src: require('./card_deck/diamonds/1.webp').default },
	D2: { alias: 'D2', src: require('./card_deck/diamonds/2.webp').default },
	D3: { alias: 'D3', src: require('./card_deck/diamonds/3.webp').default },
	D4: { alias: 'D4', src: require('./card_deck/diamonds/4.webp').default },
	D5: { alias: 'D5', src: require('./card_deck/diamonds/5.webp').default },
	D6: { alias: 'D6', src: require('./card_deck/diamonds/6.webp').default },
	D7: { alias: 'D7', src: require('./card_deck/diamonds/7.webp').default },
	D8: { alias: 'D8', src: require('./card_deck/diamonds/8.webp').default },
	D9: { alias: 'D9', src: require('./card_deck/diamonds/9.webp').default },
	D10: { alias: 'D10', src: require('./card_deck/diamonds/10.webp').default },
	D11: { alias: 'D11', src: require('./card_deck/diamonds/J.webp').default },
	D12: { alias: 'D12', src: require('./card_deck/diamonds/Q.webp').default },
	D13: { alias: 'D13', src: require('./card_deck/diamonds/K.webp').default },
	H1: { alias: 'H1', src: require('./card_deck/hearts/1.webp').default },
	H2: { alias: 'H2', src: require('./card_deck/hearts/2.webp').default },
	H3: { alias: 'H3', src: require('./card_deck/hearts/3.webp').default },
	H4: { alias: 'H4', src: require('./card_deck/hearts/4.webp').default },
	H5: { alias: 'H5', src: require('./card_deck/hearts/5.webp').default },
	H6: { alias: 'H6', src: require('./card_deck/hearts/6.webp').default },
	H7: { alias: 'H7', src: require('./card_deck/hearts/7.webp').default },
	H8: { alias: 'H8', src: require('./card_deck/hearts/8.webp').default },
	H9: { alias: 'H9', src: require('./card_deck/hearts/9.webp').default },
	H10: { alias: 'H10', src: require('./card_deck/hearts/10.webp').default },
	H11: { alias: 'H11', src: require('./card_deck/hearts/J.webp').default },
	H12: { alias: 'H12', src: require('./card_deck/hearts/Q.webp').default },
	H13: { alias: 'H13', src: require('./card_deck/hearts/K.webp').default },
};

export const PIXI_CARDS_IMAGE_LINKS = _.omit(PIXI_IMAGE_LINKS, [
	'line_seperator',
	'watermark',
	'score_box',
	'card_background',
	'back_card1',
	'glow_box',
]);

export default ImageLinks;
