import CONSTANTS from 'src/utils/constants';
import theme from 'src/utils/theme';

const USER_SECTION_HEIGHT = 90;
const GAME_AREA_HEIGHT = window.innerHeight - USER_SECTION_HEIGHT * 2;
const GAME_AREA_WIDTH = window.innerWidth <= 600 ? window.innerWidth : 500;
const X_CENTER = GAME_AREA_WIDTH / 2;
const Y_CENTER = GAME_AREA_HEIGHT / 2;
const CARD_SCALE = 0.09;
const ORIGINAL_CARD_HEIGHT = 1200;
const ORIGINAL_CARD_WIDTH = 830;
const CARD_HEIGHT = ORIGINAL_CARD_HEIGHT * CARD_SCALE;
const CARD_WIDTH = ORIGINAL_CARD_WIDTH * CARD_SCALE;

const VERTICAL_PADDING = GAME_AREA_HEIGHT * 0.115;

const MY_USER_MID_CARD_POS = {
	x: X_CENTER,
	y: GAME_AREA_HEIGHT - VERTICAL_PADDING - CARD_HEIGHT / 2,
};
const OPP_MID_CARD_POS = {
	x: X_CENTER,
	y: VERTICAL_PADDING + CARD_HEIGHT / 2,
};

const SCREEN_PADDING = 0.05 * GAME_AREA_WIDTH;

const CARD_DROP_DURATION = 500;
const CARD_PICK_DURATION = 500;

const DEAL_CARD_ANIMATION_TIME = {
	table_deck_appear: 300,
	pre_deal_delay: 1_000,
	card_animation_duration: 187,
	meld_seperate_delay: 1_000,
	discard_halfway_to_final_duration: 200,
	discard_card_animation_delay: 1_000,
	number_animation_duration: 500,
};

const UNDEALT_CARD_ANIMATION_TIME = 500;

export enum GAME_STATE {
	WAITING_FOR_PLAYERS = 'WFP',
	READY_TO_START = 'RTS',
	DEALING_CARDS = 'DC',
	FIRST_TURN = 'FT',
	PLAY_TURN = 'PT',
	GAME_RESULT_SCREEN = 'GRS',
	GAME_OVER = 'GO',
}

export enum ALLOWED_ACTIONS {
	PASS_TURN = 'PT',
	PICK_CARD = 'PC',
	DISCARD_CARD = 'DC',
	KNOCK = 'K',
	GIN = 'G',
	BIG_GIN = 'BG',
	NONE = 'NONE',
}

export enum FINISH_TYPES {
	KNOCK = 'K',
	GIN = 'G',
	BIG_GIN = 'BG',
	OPPONENT_LEFT = 'OL',
	BATTLE_CANCELLED = 'BC',
	UNDERCUT = 'U',
	DRAW = 'D',
	OPPONENT_LEFT_DISCONNECTED = 'OLD',
	ALL_TURN_SKIPPED = 'ATS',
	NONE = 'NONE',
}

export enum PICK_CARD_FROM {
	DISCARD_PILE = 'DISCARD_PILE',
	UN_DEALT_CARDS = 'UN_DEALT_CARDS',
}

const SPACE_BW_CARDS = (total_cards: number) => (CONSTANTS.WIDTH * 0.7) / (total_cards - 1);
const SPACE_BW_MELDS = (total_cards: number) => SPACE_BW_CARDS(total_cards) * 1.3;

export const GAME_CONSTANTS = {
	SPACE_BW_CARDS,
	SPACE_BW_MELDS,
	CARD_SCALE,
	VERY_BIG_ZINDEX: 100000,
	VERY_SMALL_ZINDEX: -100000,
	USER_SECTION_HEIGHT,
	GAME_AREA_HEIGHT,
	GAME_AREA_WIDTH,
	ORIGINAL_CARD_HEIGHT,
	ORIGINAL_CARD_WIDTH,
	CARD_HEIGHT,
	CARD_WIDTH,
	CARDS_TO_DEAL_AT_START: 20,
	TOTAL_CARDS: 52,
	X_CENTER,
	Y_CENTER,
	NUMBER_ANIMATION_TEXT_SIZE: 0.14 * CARD_HEIGHT,
	DISCARD_PILE_ROTATION: 5,
	EXTRA_Y_SPACE_MELDS: 15,

	CARD_DECK_INIT_POS: {
		x: -CARD_WIDTH * 1.1,
		y: Y_CENTER,
	},
	TABLE_DECK_POS: {
		x: X_CENTER - GAME_AREA_WIDTH * 0.14,
		y: Y_CENTER,
	},
	MY_USER_MID_CARD_POS,
	OPP_MID_CARD_POS,
	DISCARD_ANIMATION_MID_CARD_POS: {
		x: X_CENTER + 30,
		y: Y_CENTER - 30,
	},
	DISCARD_PILE_POS: {
		x: X_CENTER + GAME_AREA_WIDTH * 0.14,
		y: Y_CENTER,
	},
	MELD_RECTANGLE: {
		x: -ORIGINAL_CARD_WIDTH / 2 - 5,
		y: ORIGINAL_CARD_HEIGHT / 2 - ORIGINAL_CARD_HEIGHT * 0.25 + 5,
		width: ORIGINAL_CARD_WIDTH + 10,
		height: ORIGINAL_CARD_HEIGHT * 0.25,
		radius: 80,
	},
	NON_DRAGGABLE_CARD_OVERLAY: {
		x: -ORIGINAL_CARD_WIDTH / 2 - 5,
		y: -ORIGINAL_CARD_HEIGHT / 2 - 10,
		radius: 32,
	},

	EVENTS: {
		match_not_found: {
			name: 'matchNotFound',
			total_animation_time: 0,
		},
		waiting_for_opponent: {
			name: 'waitingForOpponent',
			total_animation_time: 0,
		},
		dealt_card: {
			name: 'dealtCard',
			animation_time: DEAL_CARD_ANIMATION_TIME,
			total_animation_time:
				// distribute
				DEAL_CARD_ANIMATION_TIME.table_deck_appear +
				DEAL_CARD_ANIMATION_TIME.pre_deal_delay +
				DEAL_CARD_ANIMATION_TIME.card_animation_duration * 20 +
				//discard pile
				DEAL_CARD_ANIMATION_TIME.discard_card_animation_delay +
				DEAL_CARD_ANIMATION_TIME.card_animation_duration +
				DEAL_CARD_ANIMATION_TIME.discard_halfway_to_final_duration +
				// meld separate
				DEAL_CARD_ANIMATION_TIME.meld_seperate_delay +
				DEAL_CARD_ANIMATION_TIME.card_animation_duration +
				DEAL_CARD_ANIMATION_TIME.number_animation_duration,
		},
		game_table_info: {
			name: 'gameTableInfo',
			total_animation_time: 0,
		},
		player_turn_info: {
			name: 'playerTurnInfo',
			total_animation_time: 0,
		},
		card_picked: {
			name: 'cardPicked',
			animation_time: {
				card_pick_duration: CARD_PICK_DURATION,
			},
			total_animation_time: CARD_PICK_DURATION,
		},
		card_thrown: {
			name: 'cardThrown',
			animation_time: {
				card_drop_duration: CARD_DROP_DURATION,
			},
			total_animation_time: CARD_DROP_DURATION,
		},
		game_result_screen: {
			name: 'gameResultScreen',
			animation_time: {
				incoming_duration: 500,
				layoff_time: 1000,
			},
			total_animation_time: 0,
		},
		player_active_inactive_status: {
			name: 'playerActiveInActiveStatus',
			animation_time: {},
			total_animation_time: 0,
		},
		skip_card_undealt: {
			name: 'skipCardUnDealt',
			animation_time: {
				undealt_card_animation_time: UNDEALT_CARD_ANIMATION_TIME,
			},
			total_animation_time: UNDEALT_CARD_ANIMATION_TIME,
		},
		pass_turn: {
			name: 'passTurn',
			animation_time: {},
			total_animation_time: 0,
		},
		big_gin: {
			name: 'bigGin',
			animation_time: {},
			total_animation_time: 500,
		},
	},
	USER_ACTIONS: {
		JOIN_GAME: 'joinGame',
		PASS_TURN: 'passTurn',
		PICK_CARD: 'pickCard',
		DROP_CARD: 'dropCard',
		KNOCK: 'knock',
		GIN: 'gin',
		BIG_GIN: 'bigGin',
		GTI: 'gameTableInfo',
		LEAVE_IN_GAME: 'leaveInGame',
	},
	MELD_COLORS: [theme.colors.jacarta, theme.colors.equator, theme.colors.de_york],
	GAME_FINISH: {
		space_bw_deadwood_cards_with_melds: 30, // changed this from 25 to 30 just for FTUE
		SCREEN_PADDING,
		vertical_space_bw_meld_cards: 30, // changed this from 25 to 30 just for FTUE
		score_box: {
			score_box_width: 62,
			glow_box_width: 95,
			opponent: {
				initial_x_pos: GAME_AREA_WIDTH + 0.9 * CARD_WIDTH * 1.1,
				y_pos: OPP_MID_CARD_POS.y + CARD_HEIGHT / 2 + (0.9 * CARD_WIDTH) / 2 + 20,
				final_x_pos: GAME_AREA_WIDTH - SCREEN_PADDING - (0.9 * CARD_WIDTH) / 2,
			},
			my_user: {
				initial_x_pos: GAME_AREA_WIDTH + 0.9 * CARD_WIDTH * 1.1,
				y_pos: MY_USER_MID_CARD_POS.y - CARD_HEIGHT / 2 - (0.9 * CARD_WIDTH) / 2 - 20,
				final_x_pos: GAME_AREA_WIDTH - SCREEN_PADDING - (0.9 * CARD_WIDTH) / 2,
			},
		},
	},
};
