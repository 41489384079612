import { useState } from 'react';

import SocketClass from 'src/classes/SocketClass';
import { GAME_CONSTANTS } from '../Game/constants';

// APP JWT
// Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI2NmE5ZWU1MzE5ODg0MmE4OWFhYzdkZjciLCJ1biI6IldoaXNwZXJTY3JpYmU4NzMiLCJwcCI6Imh0dHBzOi8vZDI5aTJqZzdwYWpvejAuY2xvdWRmcm9udC5uZXQvYXZhdGFycy8yLmpwZyIsImRpZCI6ImExMSIsImFwcHYiOiIxMDEiLCJsb2MiOiJlbiIsImR0IjoiaW9zIiwiaWF0IjoxNzIzMDMxNDUxLCJleHAiOjE4MTMwMzE0NTF9._EtWPb0BcKpQbqPy_zyHWe24lxBKimXAbfdYtlgFeCk

const HomeTest = () => {
	const [match_id, set_match_id] = useState('');
	const [selected_user, set_selected_user] = useState('');
	const [url, set_url] = useState('https://api-staging-gin-rummy.toaststudios.io');

	const go_to_game = () => {
		SocketClass.connect_socket(
			url,
			() => {
				SocketClass.emit(GAME_CONSTANTS.USER_ACTIONS.JOIN_GAME, {
					matchId: match_id,
					playerDetails: {
						username: selected_user,
						profilePicture: 'https://randomuser.me/api/portraits/men/94.jpg',
						lobbyId: '66b21f43d4a70bb5c78b70db',
						gameUserId: selected_user,
						registrationId: 'test',
						partnerId: '',
						partnerUserId: '',
					},
				});
			},
			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1biI6IlR1cnRsZSIsImd1aWQiOiJUdXJ0bGVfMDA5IiwicHAiOiJ0ZXN0IiwicGlkIjoidGVzdCIsImlhdCI6MTcyMzQ0NDY5N30.tGbj2QTtbQLqgSAdGrQULJtN5eb6CJmJRy-QWhFo-yA',
			true,
		);
	};

	const disconnect_socket = () => {
		SocketClass.destroy_socket();
	};

	return (
		<>
			<div>
				<p style={{ color: 'white' }}>URL</p>
				<input value={url} onChange={(e) => set_url(e.target.value)} />
				<br />
				<br />
				<p style={{ color: 'white' }}>Match Id</p>
				<input value={match_id} onChange={(e) => set_match_id(e.target.value)} />
				<br />
				<br />
				<p style={{ color: 'white' }}>Username</p>

				<input value={selected_user} onChange={(e) => set_selected_user(e.target.value)} />
				<br />

				<button onClick={go_to_game}>Go to Game</button>
				<button onClick={disconnect_socket}>Disconnect </button>
			</div>
		</>
	);
};

export default HomeTest;
