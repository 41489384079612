import _ from 'lodash';
import { useSprings, useSpring } from '@react-spring/web';
import { easings } from '@react-spring/web';
import { useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { GAME_CONSTANTS } from '../../constants';
import {
	get_finish_screen_deadwood_card_pos,
	get_finish_screen_meld_card_pos,
	get_space_between_melds,
	get_space_bw_deadwood_cards,
} from '../../helper';
import { Card_type } from 'src/utils/types';
import GameContext from '../../context';
import utils from 'src/utils/utils';
import useGameStore, { I_winner_info } from 'src/store/useGameStore';
import { game_result_screen_type } from '../../types';
import Sound from 'src/classes/Sound';
import useApp from 'src/store/useApp';
import BonusModalBody from './components/BonusModalBody';
import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';

const INCOMING_DURATION = GAME_CONSTANTS.EVENTS.game_result_screen.animation_time.incoming_duration;

const LAYOFF_TIME = GAME_CONSTANTS.EVENTS.game_result_screen.animation_time.layoff_time;

const useGameFinish = () => {
	const { my_user_id, opponent_id, set_my_user_match_score, handle_open_counter_modal } = useContext(GameContext);

	const { event_data, textures, gti, add_match_score } = useGameStore(
		useShallow((state) => ({
			event_data: state.event_data,
			textures: state.textures,
			gti: state.gti,
			add_match_score: state.add_match_score,
		})),
	);

	const { show_generic_modal, hide_generic_modal } = useApp(
		useShallow((state) => ({
			show_generic_modal: state.show_generic_modal,
			hide_generic_modal: state.hide_generic_modal,
		})),
	);

	const extra = event_data.extra as game_result_screen_type;
	const { winnerInfo } = event_data.gti as {
		startNextRound: boolean;
		winnerInfo: Partial<I_winner_info>;
	};
	const { underCutBonus, knockBonus } = gti.gameConfig;
	const { giveUndercutBonus } = winnerInfo;

	const { players, layoffCards, knockerId } = extra;

	const winner_id = extra.winnerId;

	//hard coded (only for ftue)
	const giveDeadwoodScore = 1;
	const giveKnockBonus = false;
	const giveRoundBonus = true;
	const roundProgressionBonus = 15;

	const my_user = players[my_user_id];
	const opponent = players[opponent_id];

	const my_user_score = my_user.hand.newDw;
	const opponent_score = opponent.hand.newDw;

	const is_my_user_knocker = knockerId === my_user_id;

	const opponent_melds = opponent.hand.meld;
	const my_user_meld = my_user.hand.meld;

	const opponent_deadwood = opponent.hand.hand;
	const my_user_deadwood = my_user.hand.hand;

	const [show_my_user_score, set_show_my_user_score] = useState(my_user_score);

	const opp_flat_melds = _.flatMap(opponent_melds, (melds, meld_index) => {
		return _.map(melds, (card, card_index) => ({
			meld_index,
			card_index,
			card,
		}));
	});

	const my_user_flat_melds = _.flatMap(my_user_meld, (melds, meld_index) => {
		return _.map(melds, (card, card_index) => ({
			meld_index,
			card_index,
			card,
		}));
	});

	const player_details = {
		opponent: {
			initial_y_pos: -GAME_CONSTANTS.CARD_HEIGHT * 1.1,
			space_between_melds: get_space_between_melds(opponent_melds.length, opponent_deadwood.length),
			space_bw_deadwood_cards: get_space_bw_deadwood_cards(opponent_melds.length, opponent_deadwood.length),
		},
		my_user: {
			initial_y_pos: GAME_CONSTANTS.GAME_AREA_HEIGHT + GAME_CONSTANTS.CARD_HEIGHT * 1.1,
			space_between_melds: get_space_between_melds(my_user_meld.length, my_user_deadwood.length),
			space_bw_deadwood_cards: get_space_bw_deadwood_cards(my_user_meld.length, my_user_deadwood.length),
		},
	};

	const knocker_melds_cards_post_layoff_pos = () => {
		// Determine which player's melds to use based on the knocker_id
		const knocker_melds = _.cloneDeep(is_my_user_knocker ? my_user.hand.meld : opponent.hand.meld);
		const kocker_details = is_my_user_knocker ? player_details.my_user : player_details.opponent;

		// Add layoff cards to the respective melds at the specified positions
		_.forEach(layoffCards, ({ card, knockerMeldIndex, knockerMeldCardIndex }) => {
			knocker_melds[knockerMeldIndex].splice(knockerMeldCardIndex, 0, card);
		});

		// Calculate positions for each card in the updated melds
		const positions = _.reduce(
			knocker_melds,
			(result, meld, meld_index) => {
				_.each(meld, (card, card_index) => {
					const { x, y } = get_finish_screen_meld_card_pos(
						meld_index,
						card_index,
						kocker_details.space_between_melds,
						!is_my_user_knocker,
					);
					result[card] = { x, y, zIndex: card_index };
				});
				return result;
			},
			{} as Record<Card_type, { x: number; y: number; zIndex: number }>,
		);

		return positions;
	};

	const get_my_meld_pos = (index: number) => {
		const { meld_index, card_index } = my_user_flat_melds[index];
		const { x, y } = get_finish_screen_meld_card_pos(meld_index, card_index, player_details.my_user.space_between_melds, false);

		return { x, y, meld_index, card_index };
	};

	const [my_user_meld_spring, my_user_meld_api] = useSprings(my_user_flat_melds.length, (index) => {
		const { x, meld_index, card_index } = get_my_meld_pos(index);

		return {
			from: {
				y: player_details.my_user.initial_y_pos,
				x,
				texture: textures[my_user_meld[meld_index][card_index]],
				zIndex: card_index,
			},
		};
	});

	const get_my_deadwood_pos = (index: number) => {
		const { x, y } = get_finish_screen_deadwood_card_pos(
			index,
			my_user_meld.length,
			player_details.my_user.space_between_melds,
			player_details.my_user.space_bw_deadwood_cards,
			false,
		);

		return { x, y };
	};

	const [my_user_deadwood_spring, my_user_deadwood_api] = useSprings(my_user_deadwood.length, (index) => {
		const { x: x_first_card } = get_my_deadwood_pos(0);
		return {
			from: {
				y: player_details.my_user.initial_y_pos,
				x: x_first_card,
				texture: textures[my_user_deadwood[index]],
				angle: 0,
				zIndex: index,
			},
		};
	});

	const [my_user_score_box_spring, my_user_score_box_spring_api] = useSpring(() => ({
		from: {
			x: GAME_CONSTANTS.GAME_FINISH.score_box.my_user.initial_x_pos,
			y: GAME_CONSTANTS.GAME_FINISH.score_box.my_user.y_pos,
			opacity: 1,
			texture: textures[PIXI_IMAGE_LINKS.score_box.alias],
			score: my_user_score,
			width: GAME_CONSTANTS.GAME_FINISH.score_box.score_box_width,
		},
		onChange: ({ value }) => {
			if (my_user_score !== value.score) {
				//hard coded for ftue
				set_show_my_user_score(value.score);
			}
		},
	}));

	const get_opp_melds_pos = (index: number) => {
		const { meld_index, card_index } = opp_flat_melds[index];
		const { x, y } = get_finish_screen_meld_card_pos(meld_index, card_index, player_details.opponent.space_between_melds, true);

		return { x, y, meld_index, card_index };
	};

	const [opponent_melds_spring, opponent_melds_api] = useSprings(opp_flat_melds.length, (index) => {
		const { meld_index, card_index, x } = get_opp_melds_pos(index);

		return {
			from: {
				x,
				y: player_details.opponent.initial_y_pos,
				texture: textures[opponent_melds[meld_index][card_index]],
				zIndex: card_index,
			},
		};
	});

	const get_opp_deadwood_pos = (index: number) => {
		const { x, y } = get_finish_screen_deadwood_card_pos(
			index,
			opponent_melds.length,
			player_details.opponent.space_between_melds,
			player_details.opponent.space_bw_deadwood_cards,
			true,
		);

		return { x, y };
	};

	const [opponent_deadwood_spring, opponent_deadwood_api] = useSprings(opponent_deadwood.length, (index) => {
		const { x: x_first_card } = get_my_deadwood_pos(0);

		return {
			from: {
				x: x_first_card,
				y: player_details.opponent.initial_y_pos,
				texture: textures[opponent_deadwood[index]],
				angle: 0,
				zIndex: index,
			},
		};
	});

	const [opponent_score_box_spring, opponent_score_box_api] = useSpring(() => ({
		from: {
			x: GAME_CONSTANTS.GAME_FINISH.score_box.opponent.initial_x_pos,
			y: GAME_CONSTANTS.GAME_FINISH.score_box.opponent.y_pos,
			opacity: 1,
			texture: textures[PIXI_IMAGE_LINKS.score_box.alias],
			score: opponent_score,
			width: GAME_CONSTANTS.GAME_FINISH.score_box.score_box_width,
		},
	}));

	const perform_my_user_meld_animation = () => {
		return new Promise((resolve) => {
			if (_.isEmpty(my_user_flat_melds)) {
				return resolve(null);
			}
			_.map(my_user_meld, (__, index) => {
				const delay = INCOMING_DURATION * index;
				setTimeout(() => {
					Sound.play('card_spread');
				}, delay);
			});

			my_user_meld_api.start((index) => {
				const { meld_index, y } = get_my_meld_pos(index);
				const { y: y_first_card } = get_my_meld_pos(0);

				const delay = INCOMING_DURATION * meld_index;

				return {
					to: [
						{
							y: y_first_card,
						},
						{
							y,
						},
					],
					delay,
					config: { duration: INCOMING_DURATION, easing: easings.easeOutCubic },
					onRest: () => {
						if (index === my_user_flat_melds.length - 1) {
							resolve(null);
						}
					},
				};
			});
		});
	};

	const perform_my_user_deadwood_animation = () => {
		return new Promise((resolve) => {
			if (_.isEmpty(my_user_deadwood)) {
				return resolve(null);
			}
			let completed_animations = 0;

			Sound.play('card_spread');

			my_user_deadwood_api.start((index) => {
				const { x, y } = get_my_deadwood_pos(index);
				const { x: x_first_card, y: y_first_card } = get_my_deadwood_pos(0);

				return {
					to: [
						{ x: x_first_card, y: y_first_card },
						{ x, y },
					],
					config: { duration: INCOMING_DURATION, easing: easings.easeOutCubic },
					onRest: () => {
						completed_animations += 1;
						if (completed_animations === my_user_deadwood.length) {
							resolve(null);
						}
					},
				};
			});
		});
	};

	const perform_opponent_meld_animation = () => {
		return new Promise((resolve) => {
			if (_.isEmpty(opp_flat_melds)) {
				return resolve(null);
			}

			_.map(opponent_melds, (__, index) => {
				const delay = INCOMING_DURATION * (index * 2 + 1);
				setTimeout(() => {
					Sound.play('card_spread');
				}, delay);
			});

			opponent_melds_api.start((index) => {
				const { meld_index, y } = get_opp_melds_pos(index);
				const { y: y_first_card } = get_opp_melds_pos(0);

				return {
					to: [
						{ y: y_first_card },
						{
							y,
						},
					],
					config: { duration: INCOMING_DURATION, easing: easings.easeOutCubic },
					delay: INCOMING_DURATION * meld_index,
					onRest: () => {
						if (index === opp_flat_melds.length - 1) {
							resolve(null);
						}
					},
				};
			});
		});
	};

	const perform_opponent_deadwood_animation = () => {
		return new Promise((resolve) => {
			if (_.isEmpty(opponent_deadwood)) {
				return resolve(null);
			}
			let completed_animations = 0;

			Sound.play('card_spread');

			opponent_deadwood_api.start((index) => {
				const { x, y } = get_opp_deadwood_pos(index);
				const { x: x_first_card, y: y_first_card } = get_opp_deadwood_pos(0);
				return {
					to: [
						{ x: x_first_card, y: y_first_card },
						{ x, y },
					],
					config: { duration: INCOMING_DURATION, easing: easings.easeOutCubic },
					onRest: () => {
						completed_animations += 1;
						if (completed_animations === opponent_deadwood.length) {
							resolve(null);
						}
					},
				};
			});
		});
	};

	const perform_layoff_animations = () => {
		return new Promise((resolve) => {
			if (_.isEmpty(layoffCards)) {
				resolve(null);
				return;
			}
			const final_positions = knocker_melds_cards_post_layoff_pos();

			const meld_api = is_my_user_knocker ? my_user_meld_api : opponent_melds_api;
			const deadwood_api = is_my_user_knocker ? opponent_deadwood_api : my_user_deadwood_api;
			const meld_card_flat = is_my_user_knocker ? my_user_flat_melds : opp_flat_melds;
			const deadwood_cards = is_my_user_knocker ? opponent_deadwood : my_user_deadwood;

			deadwood_api.start((index) => ({
				to: async (next) => {
					try {
						const pos = final_positions[deadwood_cards[index]];

						if (pos) {
							await next({
								...pos,
								config: { duration: LAYOFF_TIME, easing: easings.easeOutCubic },
								angle: 180,
							});
						}
					} catch (e) {
						utils.capture_exception(e);
					}
				},
				onRest: () => {
					resolve(null);
				},
			}));

			meld_api.start((index) => ({
				to: async (next) => {
					try {
						const pos = final_positions[meld_card_flat[index].card];

						if (pos) {
							await next({
								...pos,
								config: { duration: LAYOFF_TIME, easing: easings.easeInCubic },
							});
						}
					} catch (e) {
						utils.capture_exception(e);
					}
				},
			}));
		});
	};

	const perform_my_user_score_box_animation = () => {
		return new Promise((resolve) => {
			my_user_score_box_spring_api.start({
				to: {
					x: GAME_CONSTANTS.GAME_FINISH.score_box.my_user.final_x_pos,
					y: GAME_CONSTANTS.GAME_FINISH.score_box.my_user.y_pos,
				},
				config: { duration: INCOMING_DURATION, easing: easings.easeInCubic },
				delay: 500,
				onRest: () => {
					resolve(null);
				},
			});
		});
	};

	const perform_oppoent_score_box_animation = () => {
		return new Promise((resolve) => {
			opponent_score_box_api.start({
				to: {
					x: GAME_CONSTANTS.GAME_FINISH.score_box.opponent.final_x_pos,
					y: GAME_CONSTANTS.GAME_FINISH.score_box.opponent.y_pos,
				},
				config: { duration: INCOMING_DURATION, easing: easings.easeInCubic },
				onRest: () => {
					resolve(null);
				},
			});
		});
	};

	const show_deadwood_score_animation = () => {
		return new Promise((resolve) => {
			if (!giveDeadwoodScore) {
				return resolve(null);
			}

			const deadwood_diff = Math.abs(my_user_score - opponent_score);

			opponent_score_box_api.start({
				to: {
					x: GAME_CONSTANTS.GAME_FINISH.score_box.opponent.final_x_pos,
					y: GAME_CONSTANTS.Y_CENTER,
					opacity: 0,
				},
				config: { duration: 800, easing: easings.linear },
				onRest: async () => {
					await utils.delay(1800);
					set_my_user_match_score(1);
				},
			});

			my_user_score_box_spring_api.start({
				to: [
					{
						x: GAME_CONSTANTS.GAME_FINISH.score_box.my_user.final_x_pos,
						y: GAME_CONSTANTS.Y_CENTER,
						opacity: 0.2,
						config: { duration: 800, easing: easings.linear },
					},
					{
						opacity: 1,
						texture: textures[PIXI_IMAGE_LINKS.glow_box.alias],
						width: GAME_CONSTANTS.GAME_FINISH.score_box.glow_box_width,
						config: { duration: 0, easing: easings.linear },
						score: deadwood_diff,
					},
					{
						texture: textures[PIXI_IMAGE_LINKS.score_box.alias],
						width: GAME_CONSTANTS.GAME_FINISH.score_box.score_box_width,
						config: { duration: 0, easing: easings.linear },
						delay: 700,
					},
					{
						x: GAME_CONSTANTS.X_CENTER,
						y: winner_id === my_user_id ? GAME_CONSTANTS.MY_USER_MID_CARD_POS.y : GAME_CONSTANTS.OPP_MID_CARD_POS.y,
						config: { duration: 1200, easing: easings.linear },
					},
					{
						opacity: 0,
						config: { duration: 700, easing: easings.linear },
					},
				],
				onRest: async () => {
					add_match_score(winner_id, deadwood_diff);
					await utils.delay(1000);
					resolve(null);
				},
			});
		});
	};

	const show_bonus_popups = async () => {
		if (giveKnockBonus) {
			add_match_score(winner_id, knockBonus);
			Sound.play('bonus');
			show_generic_modal({
				is_closable: false,
				body_comp: <BonusModalBody text='Knock Bonus' bonus={knockBonus} />,
			});
			await utils.delay(2000);
			hide_generic_modal();
			await utils.delay(1500);
		}

		if (giveUndercutBonus) {
			add_match_score(winner_id, underCutBonus);
			Sound.play('bonus');
			show_generic_modal({
				is_closable: false,
				body_comp: <BonusModalBody text='Undercut Bonus' bonus={underCutBonus} />,
			});
			await utils.delay(2000);
			hide_generic_modal();
			await utils.delay(1500);
		}

		if (giveRoundBonus) {
			add_match_score(winner_id, roundProgressionBonus);
			Sound.play('bonus');
			show_generic_modal({
				is_closable: false,
				body_comp: <BonusModalBody text='ROUND BONUS' bonus={roundProgressionBonus} />,
			});
			await utils.delay(2000);
			set_my_user_match_score(16);
			hide_generic_modal();
			await utils.delay(1500);
		}
	};

	// const on_finish_game = () => {
	// 	handle_show_replay_screen();
	// };

	// const show_winner = async () => {
	// 	if (winner_id === '') {
	// 		handle_open_lottie_modal(Animations.draw, () => {});
	// 		return;
	// 	}

	// 	if (!startNextRound) {
	// 		let lottie_animation = {} as AnimationItem;

	// 		switch (winner_id) {
	// 			case my_user_id:
	// 				Sound.play('win');
	// 				lottie_animation = Animations.you_win;
	// 				break;
	// 			case opponent_id:
	// 				Sound.play('loss');
	// 				lottie_animation = Animations.you_lost;
	// 				break;
	// 		}

	// 		handle_open_lottie_modal(lottie_animation, () => {
	// 			if (winner_id === my_user_id) {
	// 				setTimeout(() => {
	// 					handle_open_lottie_modal(
	// 						Animations.win_amount,
	// 						() => {
	// 							setTimeout(() => {
	// 								on_finish_game();
	// 							}, 1500);
	// 						},
	// 						_.get(winningAmount, '', 0) > 0 ? `${currencySymbol} ${winningAmount}` : undefined,
	// 					);
	// 				}, 1000);
	// 			} else {
	// 				on_finish_game();
	// 			}
	// 		});
	// 	}
	// };

	const show_counter = async () => {
		handle_open_counter_modal();
	};

	useEffect(() => {
		(async () => {
			await perform_my_user_meld_animation();
			await perform_my_user_deadwood_animation();
			await perform_opponent_meld_animation();
			await perform_opponent_deadwood_animation();
			await perform_layoff_animations();
			await perform_my_user_score_box_animation();
			await perform_oppoent_score_box_animation();
			await show_deadwood_score_animation();
			await show_bonus_popups();
			await show_counter();
			await utils.delay(3000);
			set_my_user_match_score(0);
		})();
	}, []);

	return {
		my_user_meld_spring,
		my_user_meld_api,
		my_user_deadwood_spring,
		my_user_deadwood_api,
		opponent_deadwood_spring,
		opponent_deadwood_api,
		opponent_melds_spring,
		opponent_melds_api,
		my_user_score_box_spring,
		opponent_score_box_spring,
		my_user_score,
		opponent_score,
		show_my_user_score,
	};
};

export default useGameFinish;
