import { Modal, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import _ from 'lodash';

import CONSTANTS from 'src/utils/constants';
import Text from 'src/common/@the-source/atoms/Text';
import theme from 'src/utils/theme';
import { RouteNames, router } from 'src/utils/navigation';
import useGameStore from 'src/store/useGameStore';
import { ftueGTI } from 'src/mock/ftueV1.GTI';

interface Props {
	open: boolean;
	handle_counter_modal_close: () => void;
	on_close?: () => void;
}

const styles = {
	modal_container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	lottie_container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: CONSTANTS.WIDTH,
		height: '100%',
	},
	countdown_text: {
		fontSize: 100,
		color: theme.colors.equator,
		transition: 'opacity 0.5s ease-in-out',
	},
};

const CounterModal = ({ open, on_close = _.noop, handle_counter_modal_close }: Props) => {
	const [count, setCount] = useState<number | null>(null);

	const game_store = useGameStore.getState();
	const handle_play_again = () => {
		game_store.deal_cards('dealtCard', { gti: ftueGTI });
		router.navigate(RouteNames.Game);
	};

	const handle_animation_end = () => {
		on_close?.();
		handle_counter_modal_close();
		handle_play_again();
	};

	useEffect(() => {
		let timer: NodeJS.Timeout;
		let interval: NodeJS.Timeout;

		if (open) {
			timer = setTimeout(() => {
				setCount(3);
				interval = setInterval(() => {
					setCount((prevCount) => {
						if (prevCount && prevCount > 1) {
							return prevCount - 1;
						} else {
							clearInterval(interval);
							handle_animation_end();
							return 1;
						}
					});
				}, 1000); // 1 second interval
			}, 1000); // 1 second delay before setting the count to 3 and starting the countdown
		}

		return () => {
			clearTimeout(timer);
			clearInterval(interval);
			setCount(null);
		};
	}, [open]);

	if (!open) {
		return null;
	}

	return (
		<Modal open={true} sx={styles.modal_container}>
			<Box sx={styles.lottie_container}>
				<Text pridi medium component={'span'} sx={styles.countdown_text}>
					{count}
				</Text>
			</Box>
		</Modal>
	);
};

export default CounterModal;
