import _ from 'lodash';
import { Box, Modal } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import ImageLinks from 'src/assets/images/ImageLinks';
import Text from '../atoms/Text';
import useApp from 'src/store/useApp';
import theme from 'src/utils/theme';
import CONSTANTS from 'src/utils/constants';
import IconButtonSecondary from '../atoms/IconButtonSecondary';
import Button from '../atoms/Button';
import { TypeAnimation } from 'react-type-animation';
import { useEffect, useState } from 'react';
import { GAME_CONSTANTS } from 'src/screens/Game/constants';

const styles = {
	container: {
		width: 0.8 * CONSTANTS.WIDTH,
		position: 'relative',
		backgroundColor: theme.colors.black,
		border: `4px solid ${theme.colors.equator}`,
		borderRadius: 4,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
	},
	button_container: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		columnGap: '10px',
		marginTop: '20px',
	},
	title_text: {
		color: theme.colors.equator,
		fontSize: 31,
		textAlign: 'center',
	},
	sub_title_text: {
		fontSize: 13,
		color: theme.colors.white,
		textAlign: 'center',
		paddingTop: '20px',
	},
	button: {
		width: '50%',
	},
};

const GenericModal = () => {
	const { generic_modal_details, hide_generic_modal } = useApp(
		useShallow((state) => ({
			generic_modal_details: state.generic_modal_details,
			hide_generic_modal: state.hide_generic_modal,
		})),
	);

	const {
		title,
		sub_title,
		is_closable = true,
		primary_btn_text,
		secondary_btn_text,
		is_open = false,
		on_close,
		on_primary_press,
		on_secondary_press,
		should_close_on_button_click = true,
		body_comp,
	} = generic_modal_details;

	const handle_close = () => {
		hide_generic_modal();
	};

	const handle_primary = async () => {
		if (should_close_on_button_click) {
			handle_close();
		}
		await on_primary_press?.();
	};

	const handle_secondary = async () => {
		if (should_close_on_button_click) {
			handle_close();
		}

		await on_secondary_press?.();
	};

	return (
		<Modal open={is_open} onClose={on_close} closeAfterTransition>
			<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
				<Box sx={styles.container}>
					{is_closable && (
						<IconButtonSecondary
							onClick={handle_close}
							container_styles={{ position: 'absolute', right: -15, top: -15 }}
							icon={<ImageLinks.cross width={'100%'} height={'100%'} onClick={on_close} />}
						/>
					)}

					{body_comp ? (
						body_comp
					) : (
						<>
							<Text sx={styles.title_text}>{title}</Text>
							<Text sx={styles.sub_title_text}>{sub_title}</Text>

							{(primary_btn_text || secondary_btn_text) && (
								<Box sx={styles.button_container}>
									{secondary_btn_text && (
										<Button
											title={_.capitalize(secondary_btn_text)}
											container_styles={styles.button}
											onClick={handle_secondary}
										/>
									)}

									{primary_btn_text && (
										<Button
											title={_.capitalize(primary_btn_text)}
											container_styles={styles.button}
											onClick={handle_primary}
										/>
									)}
								</Box>
							)}
						</>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default GenericModal;

export const GameToolTip = ({ text, style, fontSize = '2em' }: { text: string; fontSize?: string; style?: Object }) => {
	const [defaultText, setDefaultText] = useState('');

	useEffect(() => {
		setDefaultText('');
	}, [text]);

	return (
		<Box
			sx={{
				position: 'absolute',
				minHeight: 30,
				minWidth: GAME_CONSTANTS.GAME_AREA_WIDTH / 2,
				backgroundColor: theme.colors.black,
				border: `2px solid ${theme.colors.equator}`,
				borderRadius: 4,
				padding: '7px 10px 10px 10px',
				display: 'flex',
				color: '#b1b1b1',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				...style,
				fontSize,
			}}>
			{defaultText ? (
				defaultText
			) : (
				<TypeAnimation
					sequence={[
						// Same substring at the start will only be typed once, initially
						text,
						1000,
						() => {
							setDefaultText(text);
						},
					]}
					speed={30}
					cursor={false}
					repeat={0}
					omitDeletionAnimation={true}
				/>
			)}
		</Box>
	);
};
