import React from 'react';
import _ from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import CONSTANTS from 'src/utils/constants';
import useGameStore from './useGameStore';
import SocketClass from 'src/classes/SocketClass';
import utils from 'src/utils/utils';

const initial_state = {
	is_loading: false,
	generic_modal_details: {} as {
		is_open: boolean;

		title?: string;
		sub_title?: string;
		body_comp?: React.ReactNode;
		is_closable?: boolean;
		on_close?: () => void;
		primary_btn_text?: string;
		secondary_btn_text?: string;
		border_radius?: number;
		close_button_size?: number;
		on_primary_press?: () => void;
		on_secondary_press?: () => void;
		should_close_on_button_click?: boolean;
	},

	// auth
	auth_token: null as string | null,
	refresh_token: null as string | null,

	// env
	env: CONSTANTS.ALL_ENV.staging,
};

type State = typeof initial_state;

type Actions = {
	show_loader: () => void;
	hide_loader: () => void;
	initialize_app: () => void;
	logout: () => void;
	show_generic_modal: (modal_details: Omit<State['generic_modal_details'], 'is_open'>) => void;
	hide_generic_modal: () => void;
};

const store_fn = immer<State & Actions>((set, get) => ({
	..._.cloneDeep(initial_state),
	show_loader: () => {
		set((state) => {
			state.is_loading = true;
		});
	},
	hide_loader: () => {
		set((state) => {
			state.is_loading = false;
		});
	},
	initialize_app: () => {
		useGameStore.getState().get_textures();

		if (CONSTANTS.CURRENT_ENV === CONSTANTS.ALL_ENV.production) {
			(function (c: any, l: Document, a: string, r: string, i: string, t: any, y: any) {
				c[a] =
					c[a] ||
					function () {
						(c[a].q = c[a].q || []).push(arguments);
					};
				// eslint-disable-next-line no-param-reassign
				t = l.createElement(r);
				t.async = 1;
				t.src = `https://www.clarity.ms/tag/${i}`;
				// eslint-disable-next-line no-param-reassign
				y = l.getElementsByTagName(r)[0];
				y.parentNode?.insertBefore(t, y);
			})(window, document, 'clarity', 'script', 'ntpkcz0xk0', '', null);
		}

		if ((window as any).webview_data) {
			// free win
			const { auth_token, refresh_token } = (window as any).webview_data;
			get().show_loader();
			utils.set_request_auth_header(`Bearer ${auth_token}`);
			set((state) => {
				state.auth_token = auth_token;
				state.refresh_token = refresh_token;
			});

			SocketClass.connect_socket(CONSTANTS.SOCKET_URL);
		}
	},

	logout: () => {},

	show_generic_modal: (modal_details) => {
		set((state) => {
			state.generic_modal_details = {
				is_open: true,
				...modal_details,
			};
		});
	},
	hide_generic_modal: () => {
		set((state) => {
			state.generic_modal_details.is_open = false;
		});
	},
}));

const useApp = CONSTANTS.IS_PROD ? create(store_fn) : create(devtools(store_fn));

export default useApp;
