import _ from 'lodash';

import { GAME_CONSTANTS } from './constants';
import { Card_type } from 'src/utils/types';
import { gti_type } from 'src/store/useGameStore';

type I_position = {
	card: Card_type;
	x: number;
	y: number;
	zIndex: number;

	meld_color?: string;
};

export const get_rotation_angle = (index: number, angle: number) => {
	if (index <= GAME_CONSTANTS.CARDS_TO_DEAL_AT_START) {
		if (index % 2 === 0) {
			return angle;
		}

		return -angle;
	}

	return 0;
};

export const get_starting_of_cards_pos = (number_of_cards: number) => {
	if (number_of_cards % 2 === 0) {
		return (
			GAME_CONSTANTS.X_CENTER -
			GAME_CONSTANTS.SPACE_BW_CARDS(number_of_cards) / 2 -
			GAME_CONSTANTS.SPACE_BW_CARDS(number_of_cards) * (Math.floor(number_of_cards / 2) - 1)
		);
	}

	return GAME_CONSTANTS.X_CENTER - GAME_CONSTANTS.SPACE_BW_CARDS(number_of_cards) * Math.floor(number_of_cards / 2);
};

//get new positions of player cards post distribution
export const get_my_user_card_positions = (hand: Card_type[], melds: Card_type[][]) => {
	const positions: I_position[] = [];

	const y_position = GAME_CONSTANTS.MY_USER_MID_CARD_POS.y;

	const total_cards = hand.length + _.flatten(melds).length;

	const starting_pos = get_starting_of_cards_pos(total_cards);

	// cards left to distribute space
	let meld_card_to_distribute_space = 0;
	_.map(melds, (meld) => {
		meld_card_to_distribute_space += meld.length - 1;
	});

	let deadwood_card_to_distribute_space = 0;
	if (hand.length > 0) {
		deadwood_card_to_distribute_space = hand.length - 1;
	}

	let melds_to_distribute_space = 0;
	if (melds.length > 0) {
		melds_to_distribute_space = hand.length === 0 ? melds.length - 1 : melds.length;
	}

	// space between cards distributed equally minus meld spaces
	const space_bw_cards_with_melds =
		(GAME_CONSTANTS.SPACE_BW_CARDS(total_cards) * (total_cards - 1) -
			melds_to_distribute_space * GAME_CONSTANTS.SPACE_BW_MELDS(total_cards)) /
		(deadwood_card_to_distribute_space + meld_card_to_distribute_space);

	let x_pos = starting_pos;

	// Calculate positions for hand cards
	let ctr = 1;
	for (let i = 0; i < hand.length; i++) {
		if (i !== 0) {
			x_pos = x_pos + space_bw_cards_with_melds; // Reduce the space between hand cards
		}
		positions.push({ card: hand[i], x: x_pos, y: y_position, zIndex: ctr });
		ctr++;
	}

	let meld_y_pos = y_position + GAME_CONSTANTS.EXTRA_Y_SPACE_MELDS;

	for (let i = 0; i < melds.length; i++) {
		let color = GAME_CONSTANTS.MELD_COLORS[i];

		for (let j = 0; j < melds[i].length; j++) {
			if (ctr !== 1) {
				if (j === 0) {
					x_pos += GAME_CONSTANTS.SPACE_BW_MELDS(total_cards);
				} else {
					x_pos += space_bw_cards_with_melds;
				}
			}
			positions.push({ card: melds[i][j], x: x_pos, y: meld_y_pos, meld_color: color, zIndex: ctr });
			ctr++;
		}
	}

	return positions;
};

//get final positions of player cards post distribution in the format [card]:{x,y} which we will use to get final positions of cards in animation
export const get_final_position_my_user = (hand: Card_type[], melds: Card_type[][]) => {
	const positions = get_my_user_card_positions(hand, melds);

	const final_positions = _.reduce(
		positions,
		(result, data) => {
			result[data.card] = data;
			return result;
		},
		{} as Record<Card_type, I_position>,
	);

	return final_positions;
};

export const get_space_bw_deadwood_cards = (number_of_melds: number, number_of_deadwood_cards: number) => {
	return number_of_melds === 0
		? (GAME_CONSTANTS.GAME_AREA_WIDTH - GAME_CONSTANTS.GAME_FINISH.SCREEN_PADDING * 2 - GAME_CONSTANTS.CARD_WIDTH) /
				(number_of_deadwood_cards - 1)
		: GAME_CONSTANTS.GAME_FINISH.space_bw_deadwood_cards_with_melds;
};

export const get_space_between_melds = (number_of_melds: number, number_of_deadwood_cards: number) => {
	if (number_of_melds === 0) return 0;

	const number_of_deadwood = number_of_deadwood_cards;

	const space_taken_by_cards =
		number_of_melds * GAME_CONSTANTS.CARD_WIDTH +
		(number_of_deadwood - 1) * GAME_CONSTANTS.GAME_FINISH.space_bw_deadwood_cards_with_melds +
		GAME_CONSTANTS.CARD_WIDTH;

	const space_left = GAME_CONSTANTS.GAME_AREA_WIDTH - 2 * GAME_CONSTANTS.GAME_FINISH.SCREEN_PADDING - space_taken_by_cards;

	const space_between_cards = space_left / number_of_melds;

	return space_between_cards;
};

export const get_finish_screen_meld_card_pos = (
	meld_index: number,
	card_index: number,
	space_between_melds: number,
	is_opponent: boolean,
) => {
	return {
		x:
			meld_index * (space_between_melds + GAME_CONSTANTS.CARD_WIDTH) +
			GAME_CONSTANTS.GAME_FINISH.SCREEN_PADDING +
			GAME_CONSTANTS.CARD_WIDTH / 2,

		y: is_opponent
			? GAME_CONSTANTS.OPP_MID_CARD_POS.y + card_index * GAME_CONSTANTS.GAME_FINISH.vertical_space_bw_meld_cards
			: GAME_CONSTANTS.MY_USER_MID_CARD_POS.y - card_index * GAME_CONSTANTS.GAME_FINISH.vertical_space_bw_meld_cards,
	};
};

export const get_finish_screen_deadwood_card_pos = (
	index: number,
	num_of_melds: number,
	space_between_melds: number,
	space_bw_deadwood_cards: number,
	is_opponent: boolean,
) => {
	return {
		x:
			GAME_CONSTANTS.GAME_FINISH.SCREEN_PADDING +
			num_of_melds * (space_between_melds + GAME_CONSTANTS.CARD_WIDTH) +
			GAME_CONSTANTS.CARD_WIDTH / 2 +
			space_bw_deadwood_cards * index,

		y: is_opponent ? GAME_CONSTANTS.OPP_MID_CARD_POS.y : GAME_CONSTANTS.MY_USER_MID_CARD_POS.y,
	};
};

export const get_opponent_id = (gti: gti_type) => {
	return _.keys(_.omit(gti.players, gti.gameUserId))[0];
};
