const Animations = {
	loading_animation: require('./loading_animation.json'),
	loader: require('./loader.json'),
	you_win: require('./you_win.json'),
	you_lost: require('./you_lost.json'),
	draw: require('./draw.json'),
	opponent_knock: require('./opponent_knock.json'),
	gin: require('./gin.json'),
	big_gin: require('./big_gin.json'),
	win_amount: require('./win_amount.json'),
};

export default Animations;
