import { Container } from '@pixi/react-animated';
import _ from 'lodash';

import Card from '../Card';
import useGameFinish from './useGameFinish.tsx';
import ScoreBox from './components/ScoreBox';

const GameFinish = () => {
	const {
		my_user_meld_spring,
		my_user_deadwood_spring,
		opponent_deadwood_spring,
		opponent_melds_spring,
		my_user_score_box_spring,
		opponent_score_box_spring,
		show_my_user_score,
		opponent_score,
	} = useGameFinish();

	return (
		<Container sortableChildren={true}>
			{_.map(my_user_meld_spring, (spring, index) => (
				<Card key={index} card_props={{ ...spring, is_front_card: true }} />
			))}
			{_.map(my_user_deadwood_spring, (spring, index) => (
				<Card key={index} card_props={{ ...spring, is_front_card: true }} />
			))}

			<ScoreBox {...opponent_score_box_spring} score={opponent_score.toString()} />

			{_.map(opponent_melds_spring, (spring, index) => (
				<Card key={index} card_props={{ ...spring, is_front_card: true }} />
			))}
			{_.map(opponent_deadwood_spring, (spring, index) => (
				<Card key={index} card_props={{ ...spring, is_front_card: true }} />
			))}

			<ScoreBox {...my_user_score_box_spring} score={show_my_user_score.toString()} />
		</Container>
	);
};

export default GameFinish;
