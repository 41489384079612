import utils from '../utils';

const game = {
	get_active_pool_info: () => {
		return utils.request({
			url: 'game/active-pool-info',
			method: 'GET',
		}) as Promise<{
			data: {
				poolId: string;
				endTime: string;
				status: 'OPEN_TO_JOIN' | 'MATCHMAKING_IN_PROGRESS' | 'MATCHMAKING_COMPLETED';
			};
		}>;
	},

	get_lobbies: () => {
		return utils.request({
			url: 'game/lobby',
			method: 'GET',
		}) as Promise<{
			data: {
				lobby: { _id: string; winAmount: string; prevWinAmount?: string };
			};
		}>;
	},

	play_registration: (data: { lobbyId: string }) => {
		return utils.request({
			url: 'game/register',
			method: 'POST',
			data,
		});
	},

	de_register: () => {
		return utils.request({
			url: 'game/de-register',
			method: 'POST',
		});
	},
};

export default game;
