import _ from 'lodash';
import { io, Socket } from 'socket.io-client';

import { GAME_CONSTANTS } from 'src/screens/Game/constants';
import useApp from 'src/store/useApp';
import useGameStore from 'src/store/useGameStore';
import CONSTANTS from 'src/utils/constants';
import { quit_webview } from 'src/utils/helper';
import { RouteNames, router } from 'src/utils/navigation';
import utils from 'src/utils/utils';
import Sound from './Sound';

const RETRY_TIMEOUT = 200;

class SocketClass {
	static socket: Socket | null;

	static is_socket_connected = () => _.get(this.socket, 'connected');

	static reconnect_socket = () => {
		setTimeout(() => {
			if (!this.is_socket_connected() && this.socket) {
				this.socket.connect();
			}
		}, RETRY_TIMEOUT);
	};

	static connect_socket(url: string, on_socket_connected = _.noop, auth_token = useApp.getState().auth_token, test = false) {
		this.destroy_socket();

		this.socket = io(url, {
			transports: ['websocket'],
			reconnectionAttempts: 3,
			reconnectionDelay: 1500,
			auth: {
				token: auth_token,
				test,
			},
		});

		this.socket.on('connect_error', () => {
			if (CONSTANTS.IS_FREEWIN()) {
				quit_webview();
			}
			utils.capture_exception(new Error('socket connect error'));
		});

		this.socket.on('connect', () => {
			useApp.getState().hide_loader();
			on_socket_connected();
		});

		this.socket.onAny(this.handle_events);

		this.socket.on('disconnect', () => {
			if (this.socket) {
				this.socket.removeAllListeners();
				this.socket = null;
			}
		});
	}

	static destroy_socket = () => {
		if (this.socket) {
			this.socket.disconnect();
		}
	};

	static emit = (event_name: string, event_data: any = {}, handle_ack = _.noop) => {
		if (this.socket && this.is_socket_connected()) {
			this.socket.emit(event_name, event_data, handle_ack);
		}
	};

	static go_to_game_screen = () => {
		if (router.state.location.pathname !== RouteNames.Game) {
			router.navigate(RouteNames.Game);
		}
	};

	static handle_events = (event_name: string, data: any) => {
		const game_store = useGameStore.getState();

		switch (event_name) {
			case GAME_CONSTANTS.EVENTS.game_table_info.name:
				game_store.set_gti(data);
				SocketClass.go_to_game_screen();
				break;

			case GAME_CONSTANTS.EVENTS.dealt_card.name:
				game_store.deal_cards(event_name, data);
				SocketClass.go_to_game_screen();
				break;

			case GAME_CONSTANTS.EVENTS.waiting_for_opponent.name:
				game_store.waiting_for_opponent(data);
				router.navigate(RouteNames.Matchmaking);
				break;

			case GAME_CONSTANTS.EVENTS.match_not_found.name:
				// @todo show match not found modal
				if (CONSTANTS.IS_FREEWIN()) {
					quit_webview();
				} else {
					// @todo Go to registration screen
				}
				break;

			case GAME_CONSTANTS.EVENTS.pass_turn.name:
				// game_store.pass_turn(data);
				break;

			case GAME_CONSTANTS.EVENTS.player_turn_info.name:
				Sound.play('turn_start');
				game_store.update_game_info(event_name, data);
				break;

			case GAME_CONSTANTS.EVENTS.card_picked.name:
			case GAME_CONSTANTS.EVENTS.card_thrown.name:
			case GAME_CONSTANTS.EVENTS.skip_card_undealt.name:
			case GAME_CONSTANTS.EVENTS.big_gin.name:
				game_store.update_game_info(event_name, data);
				break;

			case GAME_CONSTANTS.EVENTS.game_result_screen.name:
				game_store.game_finish(event_name, data);
				break;

			case GAME_CONSTANTS.EVENTS.player_active_inactive_status.name:
				game_store.change_opponent_status(data);
				break;

			default:
				break;
		}
	};
}

export default SocketClass;
