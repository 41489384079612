import CONSTANTS from './constants';

const refresh_expire_token_closure = () => {
	let is_called = false;
	let running_promise: any = undefined;

	return () => {
		if (!is_called) {
			is_called = true;
			running_promise = new Promise(async () => {});
		}
		return running_promise;
	};
};

export const refresh_expire_token = refresh_expire_token_closure();

export const quit_webview = () => {
	(window as any)?.ReactNativeWebView.postMessage(JSON.stringify({ event_name: CONSTANTS.WEBVIEW_EVENTS.quit }));
};
