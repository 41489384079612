import { Text } from '@pixi/react-animated';
import { ITextStyle, TextStyle } from '@pixi/text';
import { SpringValue } from '@react-spring/web';

import theme from 'src/utils/theme';

interface Props {
	x: SpringValue | number;
	y: SpringValue | number;
	text: SpringValue | string;

	alpha?: SpringValue | number;
	styles?: Partial<ITextStyle>;
	zIndex?: number;
	fontFamily?: string;
}

const PixiText = ({ styles = {}, x, y, alpha = 1, text, zIndex = 0, fontFamily = theme.fonts.pridi_medium }: Props) => {
	return (
		<Text
			text={text}
			isSprite={true}
			anchor={0.5}
			y={y}
			x={x}
			alpha={alpha}
			zIndex={zIndex}
			style={
				new TextStyle({
					fontFamily,
					fill: [theme.colors.putty, theme.colors.lemon_chiffon, theme.colors.putty],
					fillGradientStops: [0.1788, 0.527, 0.8683],
					...styles,
				})
			}
		/>
	);
};

export default PixiText;
