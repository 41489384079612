import { Box } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import Text from './Text';
import ImageLinks from 'src/assets/images/ImageLinks';

type Props = {
	title?: string;
	clickable?: boolean;
	onClick?: () => void;
	container_styles?: any;
	disabled?: boolean;
	text_styles?: React.CSSProperties;
	children?: React.ReactNode;
	Bg_image?: any;
};

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		width: 200,
		height: 50,
		cursor: 'pointer',
	},
	sub_container: {
		width: '90%',
		height: '90%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
	},
	btn_animate: {
		':active': {
			transform: 'translateY(2px)',
		},
	},
	text: {
		fontSize: 20,
	},
};

const Button = ({
	container_styles = {},
	onClick = _.noop,
	clickable = true,
	disabled = false,
	title,
	text_styles = {},
	children,
	Bg_image = ImageLinks.yellow_button,
}: Props) => {
	const handle_click = () => {
		if (clickable && !disabled) {
			onClick();
		}
	};

	return (
		<Box sx={[styles.container, container_styles, clickable && !disabled ? styles.btn_animate : {}]} onClick={handle_click}>
			{children ? (
				children
			) : (
				<>
					<Box sx={{ position: 'absolute', width: '100%', height: '100%' }}>
						<Bg_image width={'100%'} height={'100%'} />
					</Box>
					<Box sx={[styles.sub_container]}>
						<Text sx={{ ...styles.text, ...text_styles }}>{title}</Text>
					</Box>
				</>
			)}
		</Box>
	);
};

export default Button;
