import { useContext } from 'react';
import { Sprite } from '@pixi/react-animated';

import { PIXI_IMAGE_LINKS } from 'src/assets/images/ImageLinks';
import { GAME_CONSTANTS } from '../constants';
import GameContext from '../context';

const Watermark = () => {
	const { textures } = useContext(GameContext);

	return (
		<>
			<Sprite
				texture={textures[PIXI_IMAGE_LINKS.watermark.alias]}
				x={GAME_CONSTANTS.GAME_AREA_WIDTH * 0.15}
				y={GAME_CONSTANTS.GAME_AREA_HEIGHT / 2}
				anchor={0.5}
				scale={0.4}
			/>
			<Sprite
				texture={textures[PIXI_IMAGE_LINKS.watermark.alias]}
				x={GAME_CONSTANTS.GAME_AREA_WIDTH * 0.85}
				y={GAME_CONSTANTS.GAME_AREA_HEIGHT / 2}
				anchor={0.5}
				scale={{ x: -0.4, y: 0.4 }}
			/>
		</>
	);
};

export default Watermark;
