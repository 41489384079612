import { Box } from '@mui/material';
import ImageLinks from 'src/assets/images/ImageLinks';
import { ftueGTI } from 'src/mock/ftueV1.GTI';
import useGameStore from 'src/store/useGameStore';
import { RouteNames, router } from 'src/utils/navigation';
import theme from 'src/utils/theme';

export const FirstInteraction = () => {
	const game_store = useGameStore.getState();
	const handlePlayButtonClick = () => {
		game_store.deal_cards('dealtCard', { gti: ftueGTI });
		router.navigate(RouteNames.Game);
	};
	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 10,
				backgroundImage: `url(${ImageLinks.us_app_background})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			}}>
			<img src={ImageLinks.us_app_logo} alt='Gin Rummy' height={255} width={280} />
			<button
				onClick={handlePlayButtonClick}
				style={{
					backgroundImage: `url(${ImageLinks.play_button_bg})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					border: 'none',
					height: 60,
					width: 250,
					borderRadius: 10,
					fontSize: 25,
					fontWeight: 'bold',
					cursor: 'pointer',
					fontFamily: theme.fonts.brawler_regular,
				}}>
				PLAY
			</button>
		</Box>
	);
};
